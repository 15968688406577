import { defineStyleConfig } from '@chakra-ui/react'

/**
 * Base styles configuration for the `tooltip` component.
 *
 * This configuration defines the default styles for the `tooltip` component, ensuring
 * a consistent appearance and behavior across the application. The styles include
 * adjustments for both light and dark modes.
 *
 * - `borderRadius`: Adds a medium border radius to the tooltip, providing rounded corners.
 * - `fontWeight`: Sets the font weight to `normal` for a balanced text appearance.
 * - `bg`: Defines the background color of the tooltip in light mode.
 * - `color`: Sets the text color for the tooltip in light mode.
 * - `_dark`: Provides styles for the tooltip in dark mode, adjusting both background
 *   and text colors to ensure readability and consistency with dark themes.
 *
 * For more information on theming the `tooltip` component, visit the [Chakra UI Tooltip Theming Documentation](https://v2.chakra-ui.com/docs/components/tooltip/theming).
 *
 * @type {Object}
 */
const baseStyle = {
  borderRadius: 'md', // add a border radius
  fontWeight: 'normal', // change the font weight

  bg: 'mf.neutral.50',
  color: 'mf.neutral.800',

  _dark: {
    bg: 'mf.neutral.900',
    color: 'mf.neutral.200'
  }
}

/**
 * The theme configuration for the `tooltip` component.
 *
 * This configuration exports the base styles that will be applied to all `tooltip` components
 * across the application. Additional styles, variants, or sizes can be added in the future
 * to extend the theme as needed.
 *
 * - `baseStyle`: The default styles applied to all tooltips, ensuring a consistent
 *   appearance in both light and dark modes.
 *
 * For more information on theming the `tooltip` component, visit the [Chakra UI Tooltip Theming Documentation](https://v2.chakra-ui.com/docs/components/tooltip/theming).
 *
 * @type {Object}
 */
export const tooltipTheme = defineStyleConfig({ baseStyle })
