import {
  Box,
  CloseButton,
  Flex,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useToast
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { useState } from 'react'

/**
 * @typedef {Object} MFToastAdditionalProps
 * @property {boolean} [isMinimizable] - Whether the toast is minimizable.
 */

/**
 * @typedef {import('@chakra-ui/react').UseToastOptions & MFToastAdditionalProps} MFToastProps
 * Extends Chakra UI's UseToastOptions to include additional properties for customization.
 */

/**
 * @typedef {Object} MFCustomToastProps
 * @property {import('@chakra-ui/react').ToastId} [id] - The unique identifier for the toast.
 * @property {() => void} onClose - The function to call when the toast is closed.
 * @property {import('react').ReactNode} [title] - The title of the toast.
 * @property {import('react').ReactNode} [description] - The message or description of the toast.
 * @property {import('@chakra-ui/react').AlertStatus} [status] - The status of the toast ('success', 'error', 'warning', 'info').
 * @property {import('@chakra-ui/react').AlertVariant} [variant] - The variant of the toast ('subtle', 'solid', 'left-accent', 'top-accent').
 * @property {boolean} [isClosable] - Whether the toast is closable.
 * @property {boolean} [isMinimizable] - Whether the toast is minimizable.
 * @property {boolean} condition - The condition to determine if the toast should be displayed.
 */

/**
 * Prop types shared between MFCustomToast and MFToast.
 */
const sharedPropTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.node,
  description: PropTypes.node,
  status: PropTypes.oneOf(['info', 'warning', 'success', 'error', 'loading']),
  isClosable: PropTypes.bool,
  isMinimizable: PropTypes.bool
}

/**
 * MFCustomToast component renders a toast with optional minimizable functionality.
 *
 * @param {MFCustomToastProps} props - The properties for the custom toast component.
 * @returns {JSX.Element} The rendered toast component.
 */
const MFCustomToast = ({
  id,
  onClose,
  title,
  description,
  status,
  variant = 'subtle',
  isClosable,
  isMinimizable,
  ...rest
}) => {
  const [isMinimized, setIsMinimized] = useState(false)

  const toggleMinimize = () => {
    setIsMinimized(prev => !prev)
  }

  return (
    <Alert
      status={status}
      variant={variant}
      borderRadius="md"
      boxShadow="md"
      maxW={isMinimized ? '28' : '560px'}
      minW={isMinimized ? '28' : '300px'}
      ml={isMinimized ? 'auto' : undefined}
      display="block"
      position="relative"
      overflow="hidden"
      pt={isMinimized ? '3' : '6'}
      pb="3"
      px="4"
      {...rest}
    >
      <Flex justify="space-between" align="center" w="full">
        <AlertIcon />
        <Box flex="1 1 0%">
          {title && !isMinimized && (
            <AlertTitle fontSize="lg" fontWeight="bold" mb="0" mr="4">
              {title}
            </AlertTitle>
          )}
          {!isMinimized && description && <AlertDescription>{description}</AlertDescription>}
        </Box>
        <Flex alignItems="center" ml={2} position={isMinimized ? 'inherit' : 'absolute'} top="1" right="1">
          {isMinimizable && (
            <CloseButton size="sm" onClick={toggleMinimize} aria-label={isMinimized ? 'Expand' : 'Minimize'}>
              {isMinimized ? <FontAwesomeIcon icon={['far', 'expand']} /> : <FontAwesomeIcon icon={['far', 'minus']} />}
            </CloseButton>
          )}
          {isClosable && <CloseButton size="sm" onClick={() => onClose(id)} ml={isMinimizable ? 2 : 0} />}
        </Flex>
      </Flex>
    </Alert>
  )
}

MFCustomToast.propTypes = {
  ...sharedPropTypes,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['subtle', 'solid', 'left-accent', 'top-accent'])
}

/**
 * MFToast component is a wrapper around Chakra UI's toast that provides additional functionality such as minimization.
 *
 * @param {MFToastProps} props - The properties to configure the toast.
 * @returns {JSX.Element|null} The toast element if not active, otherwise null.
 */
export default function MFToast({ isMinimizable, position = 'bottom-right', condition, ...props }) {
  const toast = useToast()

  const renderToast = ({ id, ...rest }) => {
    // eslint-disable-next-line no-unused-vars
    const { position, ...toastProps } = rest

    return <MFCustomToast id={id} isMinimizable={isMinimizable} {...toastProps} />
  }

  if (!toast.isActive(props.id) && condition) {
    return toast({
      position,
      ...props,
      render: renderToast
    })
  }

  return null
}

MFToast.propTypes = {
  ...sharedPropTypes,
  position: PropTypes.oneOf(['top', 'top-right', 'top-left', 'bottom', 'bottom-right', 'bottom-left']),
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  render: PropTypes.func,
  icon: PropTypes.node,
  onCloseComplete: PropTypes.func,
  containerStyle: PropTypes.object,
  condition: PropTypes.bool.isRequired
}
