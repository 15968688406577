import { useDisclosure, useMediaQuery } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { createContext, useContext, useMemo, useState } from 'react'

import { useMFAdminAppInitializer } from '../../contexts/MFAdminAppInitializer'

/**
 * @typedef {{
 *   skin: import('../../contexts/MFAdminAppInitializer').MFAdminLayoutSkinType,
 *   menu: import('../../contexts/MFAdminAppInitializer').MFAdminLayoutMenu & { isHovered: boolean, trueDockState: boolean },
 *   SET_SKIN: import('../../contexts/MFAdminAppInitializer').MFAdminLayoutActions['SET_SKIN'],
 *   SET_MENU_STATE: import('../../contexts/MFAdminAppInitializer').MFAdminLayoutActions['SET_MENU_STATE'],
 *   SET_IS_HOVERED: import('react').Dispatch<import('react').SetStateAction<boolean>>
 *   currentlyExpandedItem: string | null,
 *   SET_CURRENTLY_EXPANDED_ITEM: import('react').Dispatch<import('react').SetStateAction<string | null>>
 * }} MFNavContextTypeProperties
 */

/**
 * @typedef {ReturnType<typeof useDisclosure>} MFNavContextType
 */

/**
 * @type {import('react').Context<MFNavContextType | null>}
 */
const MFNavContext = createContext(null)

/**
 * Custom hook to access the Sidenav context.
 *
 * @throws {Error} If the hook is used outside of the MFNavProvider.
 * @returns {MFNavContextType} The nav context value with additional properties.
 */
export function useMFNav() {
  /** @type {MFNavContextType | null} */
  const mfNav = useContext(MFNavContext)

  if (!mfNav) {
    throw new Error('Cannot use `mfNav context` outside MFNavProvider')
  }

  return { ...mfNav }
}

/**
 * MFNavProvider component to provide Sidenav context to its children.
 *
 * @param {object} props - The props for the provider.
 * @param {React.ReactNode} props.children - The children components that will have access to the context.
 * @returns {JSX.Element} The provider component that supplies the Sidenav context.
 */
export default function MFNavProvider({ children, ...props }) {
  const {
    state: {
      layout: { skin, menu }
    },
    actions: {
      layout: { SET_MENU_STATE, SET_SKIN }
    }
  } = useMFAdminAppInitializer()

  const [isHovered, setIsHovered] = useState(false)
  const [currentlyExpandedItem, setCurrentlyExpandedItem] = useState(null)

  const disclosure = useDisclosure()

  const [isDesktop] = useMediaQuery('(min-width: 62em)')

  const value = useMemo(() => {
    return {
      ...disclosure,
      skin,
      menu: {
        ...menu,
        state: isDesktop ? menu.state : 'drawer',
        isHovered: menu.state === 'dock' && isHovered,
        trueDockState: (isDesktop ? menu.state : 'drawer') === 'dock' && !isHovered
      },
      SET_MENU_STATE,
      SET_SKIN,
      SET_IS_HOVERED: setIsHovered,
      currentlyExpandedItem,
      SET_CURRENTLY_EXPANDED_ITEM: setCurrentlyExpandedItem
    }
  }, [disclosure, skin, menu, isDesktop, isHovered, SET_MENU_STATE, SET_SKIN, currentlyExpandedItem])

  return (
    <MFNavContext.Provider value={value} {...props}>
      {children}
    </MFNavContext.Provider>
  )
}

MFNavProvider.propTypes = {
  children: PropTypes.node.isRequired
}
