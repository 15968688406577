import { extendTheme } from '@chakra-ui/react'

import { alertTheme } from './components/alert'
import { buttonTheme } from './components/button'
import { drawerTheme } from './components/drawer'
import { inputTheme } from './components/input'
import { linkTheme } from './components/link'
import { menuTheme } from './components/menu'
import { modalTheme } from './components/modal'
import { tooltipTheme } from './components/tooltip'
import { mfColors } from './mfColors'

/**
 * Extends the Chakra UI Pro theme.
 *
 * The `proTheme` object is an extension of the default Chakra UI Pro theme, which serves
 * as a base for further customization within the application.
 *
 * @type {Object}
 */
// const proTheme = extendTheme(theme)

/**
 * The custom theme configuration for the application using Chakra UI.
 *
 * This configuration extends the Chakra UI theme with custom settings specific to the
 * application's branding and design system. It includes custom fonts, colors, shadows,
 * and component themes.
 *
 * - `config`: General configuration settings for the theme.
 *   - `cssVarPrefix`: A prefix for CSS variables to avoid conflicts with other themes.
 *   - `initialColorMode`: Sets the initial color mode of the application to 'light'.
 *   - `useSystemColorMode`: Disables the use of the system's color mode setting, enforcing
 *     the application's color mode configuration.
 *
 * - `fonts`: Custom fonts for the application, using 'Plus Jakarta Sans' for both body and heading text.
 *
 * - `colors`: Custom color palette for the application, including the `mfColors` object for
 *   brand-specific colors and Chakra UI Pro theme colors.
 *
 * - `shadows`: Custom shadow settings, including an `outline` shadow used for focus states.
 *
 * - `components`: Theme overrides and customizations for various Chakra UI components,
 *   including `Drawer`, `Tooltip`, `Button`, `Menu`, and `Link`.
 *
 * @type {Object}
 */
export const mfChakraTheme = extendTheme({
  config: {
    cssVarPrefix: 'mf',
    initialColorMode: 'light',
    useSystemColorMode: false
  },

  styles: {
    global: {
      body: {
        fontFamily: 'Plus Jakarta Sans, sans-serif !important',
        lineHeight: 'base',
        color: 'mf.black !important',

        _dark: {
          color: 'mf.white !important'
        }
      },

      a: {
        color: 'mf.blue.700 !important',

        _hover: {
          color: 'mf.blue.500 !important'
        },

        _dark: {
          color: 'mf.cyan.400 !important',
          _hover: {
            color: 'mf.cyan.500 !important'
          }
        }
      }
    }
  },

  fonts: {
    body: 'Plus Jakarta Sans, sans-serif',
    heading: 'Plus Jakarta Sans, sans-serif'
  },

  colors: {
    // ...proTheme.colors,
    mf: mfColors
  },

  shadows: {
    sm: `0 1px 2px 0 ${mfColors.blackAlpha[300]}`,
    base: `0 1px 3px 0 ${mfColors.blackAlpha[300]}, 0 1px 2px 0 ${mfColors.blackAlpha[300]}`,
    md: `0 4px 6px -1px ${mfColors.blackAlpha[300]}, 0 2px 4px -1px ${mfColors.blackAlpha[300]}`,
    lg: `0 10px 15px -3px ${mfColors.blackAlpha[300]}, 0 4px 6px -2px ${mfColors.blackAlpha[300]}`,
    xl: `0 20px 25px -5px ${mfColors.blackAlpha[300]}, 0 10px 10px -5px ${mfColors.blackAlpha[300]}`,
    '2xl': `0 25px 50px -12px ${mfColors.blackAlpha[300]}`,
    outline: '0 0 0 2px rgba(203, 201, 254, 0.8)',
    inner: `inset 0 2px 4px 0 ${mfColors.blackAlpha[300]}`,
    'dark-lg': `${mfColors.blackAlpha[500]} 0px 0px 0px 1px, ${mfColors.blackAlpha[300]} 0px 5px 10px, ${mfColors.blackAlpha[300]} 0px 15px 40px`
  },

  components: {
    Alert: alertTheme,
    Button: buttonTheme,
    Drawer: drawerTheme,
    Input: inputTheme,
    Link: linkTheme,
    Menu: menuTheme,
    Modal: modalTheme,
    Tooltip: tooltipTheme
  }
})
