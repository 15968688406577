/** @type {import('../types/agencies').MFAdminAgencyResponse} */
const initialState = {
  agencies: [],
  currentAgency: null,
}

const agenciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_AGENCIES':
      return {
        ...state,
        agencies: action.payload,
      }
    case 'SET_CURRENT_AGENCY':
      return {
        ...state,
        currentAgency: action.payload,
      }
    case 'CLEAR_CURRENT_AGENCY':
      sessionStorage.removeItem('agencyAuthToken')
      return {
        ...state,
        currentAgency: null
      }
    default:
      return state
  }
}

export default agenciesReducer
