import { Button, FormControl, FormErrorMessage, Heading, Input, Stack, useColorMode } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { forgotAdminPassword } from '../../actions'
import { useMFAdminAppInitializer } from '../../contexts/MFAdminAppInitializer'
import MFLogo from '../logo'

const ForgotPasswordForm = () => {
  const {
    state: {
      layout: { skin }
    }
  } = useMFAdminAppInitializer()

  const { colorMode } = useColorMode()

  const { register, handleSubmit, formState } = useForm({ mode: 'onBlur' })

  const dispatch = useDispatch()

  const onSubmit = data => {
    dispatch(forgotAdminPassword(data.email))
  }

  return (
    <Stack>
      <Stack spacing="8">
        <Stack spacing="6" align="center">
          <MFLogo textFill={skin === 'light' ? 'black' : 'white'} size={280} />
          <Stack spacing="3" textAlign="center">
            <Heading size="xs" mb="0" color="mf.black" _dark={{ color: 'mf.white' }}>
              Password Reset
            </Heading>
            {/* <Text mb="0" color="mf.neutral.600" _dark={{ color: 'mf.neutral.200' }}>
              Enter your email to reset your password
            </Text> */}
          </Stack>
        </Stack>
        <Stack spacing="6">
          <Stack as="form" spacing="4" onSubmit={handleSubmit(onSubmit)}>
            <FormControl isRequired isInvalid={formState.errors.email}>
              <Input
                type="email"
                placeholder="Enter your email"
                {...register('email', {
                  required: 'Email address is required',
                  validate: v =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email address must be a valid address'
                })}
              />
              <FormErrorMessage>{formState.errors?.email?.message}</FormErrorMessage>
            </FormControl>
            <Button type="submit" colorScheme={colorMode === 'light' ? 'mf.black' : 'mf.primary'}>
              Reset Password
            </Button>
          </Stack>
          <Link to="/login">Return to Sign In</Link>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ForgotPasswordForm
