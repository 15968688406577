import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  Text,
  useColorMode
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { clearLoginStatus, createSession } from '../../actions/sessions'
import { GoogleIcon, MicrosoftIcon } from '../../assets/auth0_buttons/ProviderIcons'
import { REACT_APP_AUTH0_REDIRECT_URI } from '../../Constants'
import { useMFAdminAppInitializer } from '../../contexts/MFAdminAppInitializer'
import MFLogo from '../logo'

const SessionNew = ({ errors, status, createSession, clearLoginStatus }) => {
  const [formData, setFormData] = useState('') // 2FA token
  const [spinnerVisibility, setSpinnerVisibility] = useState(false)
  // const [successVisibility, setSuccessVisibility] = useState(false)
  const [tokenRequired, setTokenRequired] = useState(false)
  const [tokenResent, setTokenResent] = useState(false)

  const {
    state: {
      env,
      layout: { skin }
    }
  } = useMFAdminAppInitializer()

  const { colorMode } = useColorMode()

  const { register, handleSubmit, formState } = useForm({ mode: 'onBlur' })

  const tokenValue = useMemo(() => {
    if (env === 'development' && errors[1] === 'token_required') {
      const message = errors[0]
      const regex = /-(\d+)-/
      const match = message.match(regex)

      return match ? match[1] : undefined
    }
  }, [env, errors])

  const onSubmit = data => {
    setFormData(data)
    createSession(data)
  }

  useEffect(() => {
    const currentStatus = status.message
    switch (currentStatus) {
      case 'SUCCESS':
        setSpinnerVisibility(false)
        // setSuccessVisibility(true)
        break
      case 'FAILED':
        setSpinnerVisibility(false)
        // setSuccessVisibility(false)
        setTokenRequired(errors.length !== 0 && errors[1] === 'token_required')
        break
      case 'LOADING':
        setSpinnerVisibility(true)
        // setSuccessVisibility(false)
        break
      default:
        break
    }
  }, [status, errors])

  // on unmount, reset status
  useEffect(() => {
    clearLoginStatus()
  }, [clearLoginStatus])

  const resendToken = useCallback(() => {
    if (tokenRequired && !tokenResent) {
      createSession(formData)
      setTokenResent(true)
    }
  }, [createSession, formData, tokenRequired, tokenResent])

  const { loginWithRedirect } = useAuth0()

  const handleGoogleSignIn = async () => {
    await loginWithRedirect({
      authorizationParams: {
        connection: 'google-oauth2',
        redirectUri: REACT_APP_AUTH0_REDIRECT_URI,
        prompt: 'select_account'
      }
    })
  }

  const handleMicrosoftSignIn = async () => {
    await loginWithRedirect({
      authorizationParams: {
        connection: 'windowslive',
        redirectUri: REACT_APP_AUTH0_REDIRECT_URI,
        prompt: 'select_account'
      }
    })
  }

  return (
    <Stack>
      <Stack spacing="8">
        <Stack spacing="6" align="center">
          <MFLogo textFill={skin === 'light' ? 'black' : 'white'} size={280} />
          <Stack spacing="3" textAlign="center">
            <Heading size="xs" mb="0" color="mf.black" _dark={{ color: 'mf.white' }}>
              Sign In
            </Heading>
            {/* <Text mb="0" color="mf.neutral.600" _dark={{ color: 'mf.neutral.200' }}>
              {tokenRequired ? 'Input provided token' : 'Sign In to your account'}
            </Text> */}
          </Stack>
        </Stack>
        <Stack spacing="6">
          <Stack as="form" spacing="4" onSubmit={handleSubmit(onSubmit)}>
            <FormControl hidden={tokenRequired} isRequired isInvalid={formState.errors.email}>
              <Input
                type="email"
                placeholder="Enter your email"
                {...register('email', {
                  required: { value: !tokenRequired, message: 'Email address is required' },
                  validate: v =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email address must be a valid address'
                })}
              />
              <FormErrorMessage>{formState.errors?.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl hidden={tokenRequired} isRequired isInvalid={formState.errors.password}>
              <Input
                type="password"
                placeholder="Enter your password"
                {...register('password', {
                  required: { value: !tokenRequired, message: 'Password is required' }
                })}
              />
              <FormErrorMessage>{formState.errors?.password?.message}</FormErrorMessage>
            </FormControl>
            {tokenRequired && (
              <FormControl isRequired={tokenRequired} isInvalid={formState.errors.password}>
                <Input
                  autoFocus
                  type="text"
                  placeholder="Enter your token"
                  value={tokenValue}
                  {...register('token', {
                    required: { value: tokenRequired, message: 'Token is required' }
                  })}
                />
              </FormControl>
            )}
            <Button
              type="submit"
              colorScheme={colorMode === 'light' ? 'mf.black' : 'mf.primary'}
              isLoading={spinnerVisibility}
            >
              Continue with {tokenRequired ? 'token' : 'email'}
            </Button>
            {tokenRequired && !tokenResent && (
              <Button type="button" variant="link" size="sm" onClick={resendToken} colorScheme="mf.neutral">
                Re-Send Token
              </Button>
            )}
          </Stack>
          <Divider />
          <Button
            variant="outline"
            colorScheme="mf.neutral"
            leftIcon={<GoogleIcon />}
            // px="25%"
            justifyContent="center"
            gap="2"
            onClick={handleGoogleSignIn}
          >
            Continue with Google
          </Button>
          <Button
            variant="outline"
            colorScheme="mf.neutral"
            leftIcon={<MicrosoftIcon />}
            // px="25%"
            justifyContent="center"
            gap="2"
            onClick={handleMicrosoftSignIn}
          >
            Continue with Microsoft
          </Button>
        </Stack>
        <Link to="/forgot_password">Forgot Your Password?</Link>
        <Text textStyle="xs" color="fg.subtle" textAlign="center">
          By continuing, you acknowledge that you have read, understood, and agree to our{' '}
          <Link to="#">terms and conditions</Link>
        </Text>
      </Stack>
    </Stack>
  )
}

SessionNew.propTypes = {
  errors: PropTypes.array.isRequired,
  status: PropTypes.shape({ message: PropTypes.string }).isRequired,
  createSession: PropTypes.func.isRequired,
  clearLoginStatus: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  session: Object.values(state.session),
  errors: Object.values(state.errors),
  status: state.status
})

export default connect(mapStateToProps, { createSession, clearLoginStatus })(SessionNew)
export { SessionNew }
