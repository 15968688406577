import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import SsoFeaturebaseRedirect from '../components/external_sso/SsoFeaturebaseRedirect.js'
import LoadingSpinner from '../components/general/LoadingSpinner'
import SSoSignInPage from '../components/sessions/SSoSignInPage'

import SessionsLayout from '../containers/SessionsLayout/SessionsLayout'
import ResolveRoutes from './ResolveRoutes.js'

// const NotAuthorized = lazy(() => import('../views/pages/misc/NotAuthorized'))

const MFRouter = props => {
  const currentAgencyPresent = props.currentAgency

  return (
    <Switch>
      {/* Sessions layout should be publicly accessible */}
      <Route
        exact
        path={['/login', '/forgot_password', '/reset_password/:token', '/auth0_callback']}
        component={SessionsLayout}
      />
      <Route path="/sso/external/featurebase" component={SsoFeaturebaseRedirect} />
      <Route path="/sso-log-in/:sso_id/:sso_token" component={SSoSignInPage} />
      {currentAgencyPresent ? <ResolveRoutes {...props} /> : <LoadingSpinner />}
    </Switch>
  )
}

const mapStateToProps = state => {
  return {
    currentAdmin: state.admins.current_admin,
    currentAgency: state.agencies.currentAgency
  }
}

export default connect(mapStateToProps)(MFRouter)
