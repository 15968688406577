import { Avatar, AvatarBadge } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

/**
 * @typedef {Object} MFAdminAvatarBaseProps
 * @property {import('../../types/admins').MFAdminAvatar} userImg - current_admin.avatar value
 * @property {'Super Admin' | 'Client Admin' | 'Admin'} adminType - Union of possible admin types. This string value should be calculated based on the current_admin property values for is_super_admin and is_client_admin
 * @property {boolean} withBadge - A flag to determine if the avatar badge should be displayed.
 */

/**
 * @typedef {MFAdminAvatarBaseProps & import('@chakra-ui/react').AvatarProps} MFAdminAvatarProps
 * A combination of base props for the MFAdminAvatar component and all other props supported by Chakra UI's Avatar component.
 */
const propTypes = {
  /**
   * @type {MFAdminImg}
   * @default undefined
   * If not provided or if value is falsey, Chakra's default fallbacks will take effect - if name prop is provided, fallback will be initials - If not, fallback will be user icon
   */
  userImg: PropTypes.shape({
    url: PropTypes.string,
    small: PropTypes.shape({ url: PropTypes.string }),
    medium: PropTypes.shape({ url: PropTypes.string }),
    initial: PropTypes.shape({ url: PropTypes.string })
  }),

  /**
   * @type {'Super Admin' | 'Client Admin' | 'Admin'}
   * @default 'Admin'
   */
  adminType: PropTypes.oneOf(['Super Admin', 'Client Admin', 'Admin']),

  /**
   * @type {boolean}
   * @default false
   */
  withBadge: PropTypes.bool,

  // Extend with any other props Avatar accepts
  ...Avatar.propTypes
}

/**
 * MFAvatar component to display a user's avatar with an optional admin badge.
 *
 * @param {MFAdminAvatarProps} props - The props for the MFAvatar component.
 * @returns {JSX.Element} The rendered MFAvatar component.
 *
 * @example
 * <MFAvatar
 *   userImg={{ url: 'https://example.com/image.png', small: { url: 'https://example.com/small.png' }, medium: { url: 'https://example.com/medium.png' }, initial: { url: 'https://example.com/initial.png' } }}
 *   name="John Doe"
 *   adminType="Super Admin"
 *   size="lg"
 *   withBadge
 * />
 */
export default function MFAvatar({ userImg, name, adminType = 'Admin', size = 'md', withBadge = false, ...props }) {
  const adminAvatar = useMemo(() => {
    if (!userImg) return null

    const isSmall = size === '2xs' || size === 'xs' || size === 'sm'
    const isLarge = size === 'lg' || size === 'xl' || size === '2xl' || size === 'full'

    if (isSmall) return userImg?.small.url || userImg?.url || userImg?.medium.url || userImg?.initial.url
    if (isLarge) return userImg?.url || userImg?.initial.url || userImg?.medium.url || userImg?.small.url

    return userImg?.medium.url || userImg?.url || userImg?.initial.url || userImg?.small.url
  }, [userImg, size])

  const adminAvatarBadge = useMemo(() => {
    if (adminType.toLowerCase() === 'super admin') return 'green.500'
    if (adminType.toLowerCase() === 'client admin') return 'blue.500'
    return 'pink.500'
  }, [adminType])

  return (
    <Avatar
      size={size}
      src={adminAvatar}
      bg="mf.primary.400"
      color="mf.primary.50"
      name={name}
      aria-label={`${name} Avatar`}
      {...props}
    >
      {withBadge && <AvatarBadge bg={adminAvatarBadge} boxSize="0.875em" aria-hidden="true" />}
    </Avatar>
  )
}

MFAvatar.propTypes = propTypes
