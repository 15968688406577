import { Box, VStack } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import navWithPermissions from '../../_nav'
import { mdAdminPropType } from '../../types/admins'

import { useMFNav } from './MFNavContext'
import MFNavNavItem from './MFNavNavItem'

/**
 * MFNavSidebarNav component renders the navigation items within the sidebar.
 *
 * This component dynamically generates a list of navigation items based on the permissions
 * and the current admin user. It utilizes a custom scrollbar style and adapts to light and dark modes.
 *
 * @param {Object} props - The properties for the component.
 * @param {Object} props.currentAdmin - The current admin user.
 * @param {boolean | string[]} props.permissions - The permissions associated with the admin user.
 * @returns {JSX.Element} The rendered navigation sidebar component.
 */
export default function MFNavSidebarNav({ currentAdmin, permissions }) {
  const { skin } = useMFNav()

  const navItems = useMemo(() => {
    if (permissions || permissions === false) return navWithPermissions(permissions, currentAdmin, skin).items
    return []
  }, [permissions, currentAdmin, skin])

  return (
    <Box
      position="relative"
      w="full"
      h="full"
      overflowX="hidden"
      overflowY="auto"
      p="3"
      pb="20"
      sx={{
        '--webkit-scrollbar': '0.5rem',
        '--scrollbar-track': 'transparent',
        '--scrollbar-thumb': '#CBC9FE',
        '--scrollbar-thumb-radius': '999px',
        scrollbarWidth: 'thin',
        scrollbarColor: '#CBC9FE transparent',
        _dark: {
          '--scrollbar-thumb': '#7B67F9',
          scrollbarColor: '#7B67F9 transparent'
        }
      }}
    >
      <VStack spacing="0" mt="3">
        {navItems.map(item => (
          <MFNavNavItem key={item.id} {...item} link={{ type: 'internal', url: item.navLink }} />
        ))}
      </VStack>
    </Box>
  )
}

MFNavSidebarNav.propTypes = {
  currentAdmin: mdAdminPropType,
  permissions: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]).isRequired
}
