import { Box, Link, useMediaQuery } from '@chakra-ui/react'
// ** React Imports
import classnames from 'classnames'
import { useState, useMemo } from 'react'
import { ArrowUp } from 'react-feather'
import { useSelector } from 'react-redux'
import { useLocation, NavLink } from 'react-router-dom'

// ** Store & Actions

// ** Third Party Components
import ScrollToTop from 'react-scroll-up'
import { Button } from 'reactstrap'

// ** Configs
import MFToast from '../../components/MFMinimizableToast'
import MFNav from '../../components/MFNav'
import themeConfig from '../../configs/themeConfig'

// ** Custom Components
import { useMFAdminAppInitializer } from '../../contexts/MFAdminAppInitializer'

// ** Custom Components
import FooterComponent from './components/footer'

// ** Custom Hooks
// import { useSkin } from '../../utility/hooks/useSkin'

// ** Styles
import '../../@core/scss/base/core/menu/menu-types/vertical-menu.scss'
import '../../@core/scss/base/core/menu/menu-types/vertical-overlay-menu.scss'

const VerticalLayout = props => {
  // ** Props
  const { children, navbar, footer, routerProps, currentActiveItem } = props

  const {
    state: {
      layout: {
        skin,
        navbar: { type: navbarType, backgroundColor: navbarColor },
        footer: { type: footerType }
      }
    }
  } = useMFAdminAppInitializer()

  // ** States
  // const [isMounted, setIsMounted] = useState(false)
  // const [menuVisibility, setMenuVisibility] = useState(false)

  const [isDesktop] = useMediaQuery('(min-width: 62em)')
  // ** Store Vars
  // const { menuCollapsed, menuHidden } = useSelector(state => state.layout)

  // ** Vars
  const location = useLocation()

  // ** Handles Content Width
  // const setContentWidth = val => dispatch(handleContentWidth(val))

  // ** Handles Content Width
  // const setIsHidden = val => dispatch(handleMenuHidden(val))

  //** This function will detect the Route Change and will hide the menu on menu item click
  // useEffect(() => {
  //   if (menuVisibility && !isDesktop) {
  //     setMenuVisibility(false)
  //   }
  // }, [location])

  //** ComponentDidMount
  // useEffect(() => {
  //   setIsMounted(true)
  //   return () => setIsMounted(false)
  // }, [])

  const [userNavigation, setUserNavigation] = useState(null)
  // const adminPermissionCallback = permissions => {
  //   let navObj = navigation(permissions, null, skin)
  //   setUserNavigation(navObj)
  // }

  const currentAdmin = useSelector(state => state.admins.current_admin)
  const orderErrors = useSelector(state => state.order_errors_count)

  const showOrderErrorsMsg = useMemo(() => {
    if (currentAdmin.is_super_admin) return orderErrors > 0
    return false
  }, [currentAdmin.is_super_admin, orderErrors])
  // ** Vars
  const footerClasses = {
    static: 'footer-static',
    sticky: 'footer-fixed',
    hidden: 'footer-hidden'
  }

  const navbarWrapperClasses = {
    floating: 'navbar-floating',
    sticky: 'navbar-sticky',
    static: 'navbar-static',
    hidden: 'navbar-hidden'
  }

  const navbarClasses = {
    floating: 'floating-nav',
    sticky: 'fixed-top',
    static: 'navbar-static-top',
    hidden: 'd-none'
  }

  const bgColorCondition = navbarColor !== '' && navbarColor !== 'light' && navbarColor !== 'white'

  return (
    <Box
    // className={classnames(
    //   `wrapper vertical-layout ${navbarWrapperClasses[navbarType] || 'navbar-floating'} ${
    //     footerClasses[footerType] || 'footer-static'
    //   }`,
    //   {
    //     // Modern Menu
    //     'vertical-menu-modern': isDesktop,
    //     'menu-collapsed': menu.state === 'dock' && isDesktop,
    //     'menu-expanded': menu.state === 'full' && isDesktop,

    //     // Overlay Menu
    //     'vertical-overlay-menu': !isDesktop,
    //     'menu-hide': menu.state === 'none' || menu.state === 'drawer',
    //     // 'menu-open': menuVisibility && !isDesktop
    //   }
    // )}
    // {...((menu.state !== 'dock' && menu.state !== 'full') ? { 'data-col': '1-column' } : {})}
    >
      {/* <DefaultHeader adminPermissionCallback={adminPermissionCallback} /> */}

      <MFNav
      // adminPermissionCallback={adminPermissionCallback}
      // menu={menu}
      // menuVisibility={menuVisibility}
      // setMenuVisibility={setMenuVisibility}
      // routerProps={routerProps}
      // currentActiveItem={currentActiveItem}
      />
      <MFToast
        title="Orders stuck in processing"
        description={
          <Link as={NavLink} to="/orders/errors" colorScheme="red">
            {orderErrors} orders are stuck in processing
          </Link>
        }
        id="stuck-orders"
        isClosable
        isMinimizable
        duration={null}
        status="error"
        condition={showOrderErrorsMsg}
      />

      {/* <Navbar
        expand="lg"
        light={skin !== 'dark'}
        dark={skin === 'dark' || bgColorCondition}
        color={bgColorCondition ? navbarColor : undefined}
        className={classnames(
          `header-navbar navbar align-items-center ${navbarClasses[navbarType] || 'floating-nav'} navbar-shadow`
        )}
      >
        <div className="navbar-container d-flex content">
          {navbar ? navbar : <NavbarComponent setMenuVisibility={setMenuVisibility} />}
        </div>
        <div>inside nav bar</div>
      </Navbar> */}

      {children}

      {/* Vertical Nav Menu Overlay */}
      {/* <div
        className={classnames('sidenav-overlay', {
          show: menuVisibility
        })}
        onClick={() => setMenuVisibility(false)}
      ></div> */}
      {/* Vertical Nav Menu Overlay */}

      {/* Don't delete - allows for real time testing of different visual elements */}
      {/* {themeConfig.layout.customizer === true ? (
        <Customizer
          skin={skin}
          setSkin={setSkin}
          footerType={footerType}
          setFooterType={setFooterType}
          navbarType={navbarType}
          setNavbarType={setNavbarType}
          navbarColor={navbarColor}
          setNavbarColor={setNavbarColor}
          isRtl={isRtl}
          setIsRtl={setIsRtl}
          layout={props.layout}
          setLayout={props.setLayout}
          isHidden={isHidden}
          setIsHidden={setIsHidden}
          contentWidth={contentWidth}
          setContentWidth={setContentWidth}
          menuCollapsed={menuCollapsed}
          setMenuCollapsed={setMenuCollapsed}
          transition={props.transition}
          setTransition={props.setTransition}
          themeConfig={themeConfig}
        />
      ) : null} */}

      <footer
        className={classnames(`footer footer-light ${footerClasses[footerType] || 'footer-static'}`, {
          'd-none': footerType === 'hidden'
        })}
      >
        {footer ? footer : <FooterComponent footerType={footerType} footerClasses={footerClasses} />}
      </footer>

      {themeConfig.layout.scrollTop === true ? (
        <div className="scroll-to-top">
          <ScrollToTop showUnder={300} style={{ bottom: '5%' }}>
            <Button className="btn-icon" color="primary">
              <ArrowUp size={14} />
            </Button>
          </ScrollToTop>
        </div>
      ) : null}
    </Box>
  )
}

export default VerticalLayout
