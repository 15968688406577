import _ from 'lodash'
import { FETCH_CLIENTS, CREATE_CLIENT, FETCH_CLIENT, EDIT_CLIENT, DELETE_CLIENT } from '../actions/types'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_CLIENTS:
      return { ...state, ..._.mapKeys(action.payload, 'id') }
    case FETCH_CLIENT:
      return { ...state, [action.payload.id]: action.payload }
    case CREATE_CLIENT:
      return { ...state, [action.payload.id]: action.payload }
    case EDIT_CLIENT:
      return { ...state, [action.payload.id]: action.payload }
    case DELETE_CLIENT:
      return _.omit(state, action.payload)
    default:
      return state
  }
}
