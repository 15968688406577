import { Box, Button, Divider, HStack, IconButton, Text, Tooltip, VStack } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import { mdAdminPropType } from '../../types/admins'

import MFAvatar from '../MFAvatar'
import MFNavAvatarMenu from './MFNavAvatarMenu'
import { useMFNav } from './MFNavContext'
import MFNavNavItem from './MFNavNavItem'

/**
 * @typedef {Object} MFNavFooterProps
 * @property {import('../../types/admins').MFAdmin} currentAdmin - The current admin user.
 * @property {'Super Admin' | 'Client Admin' | 'Admin'} adminType - The type of admin user.
 */

/**
 * MFNavSidebarFooter component renders the footer section of the navigation sidebar.
 *
 * This footer includes links to external resources like support and feedback, as well as
 * an avatar menu for the current admin user. The appearance of the footer adapts based on the
 * current state of the sidebar (docked or expanded).
 *
 * @param {MFNavFooterProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered sidebar footer component.
 */
export default function MFNavSidebarFooter({ currentAdmin, adminType }) {
  const {
    menu: { trueDockState },
    SET_MENU_STATE
  } = useMFNav()

  return (
    <VStack as="footer" w="full">
      <VStack w="full" p="3" spacing="0">
        <MFNavNavItem
          id="support"
          title="Help & Support"
          icon={<FontAwesomeIcon icon="fa-duotone fa-solid fa-square-question" />}
          link={{
            type: 'external',
            url: `https://support.marketfuel.com/`
          }}
        />
        {!trueDockState ? (
          <Button
            variant="nav"
            colorScheme="mf.neutral"
            size="full"
            aria-label="Latest Changes"
            fontSize="sm"
            key="changelog"
            leftIcon={<FontAwesomeIcon icon="fa-duotone fa-solid fa-bullhorn" />}
            data-featurebase-changelog
            onClick={() => SET_MENU_STATE('full')}
          >
            Latest Changes
          </Button>
        ) : (
          <IconButton
            variant="nav"
            colorScheme="mf.neutral"
            size="sm"
            justifyContent="center"
            aria-label="Latest Changes"
            icon={<FontAwesomeIcon icon="fa-duotone fa-solid fa-bullhorn" />}
            data-featurebase-changelog
            onClick={() => SET_MENU_STATE('full')}
          />
        )}
        <MFNavNavItem
          id="feedback"
          title="Give Us Feedback"
          icon={<FontAwesomeIcon icon="fa-duotone fa-solid fa-message-dots" />}
          link={{
            type: 'external',
            url: `https://feedback.marketfuel.com?jwt=${currentAdmin.featurebase_authentication_token}`
          }}
        />
        {/* <MFNavNavItem
              id="roadmap"
              title="Current Roadmap"
              icon={<FontAwesomeIcon icon="fa-duotone fa-solid fa-map" />}
              link={{ type: 'external', url: 'https://feedback.marketfuel.com/roadmap' }}
            /> */}
      </VStack>
      <Divider my="0" />
      <HStack w="full" p="3" spacing="3" justify={trueDockState ? 'center' : 'space-between'}>
        <Tooltip label={currentAdmin.name} placement="right">
          <HStack spacing="3" alignItems="center" hidden={trueDockState}>
            <MFAvatar size="sm" userImg={currentAdmin.avatar} adminType={adminType} name={currentAdmin.name} />
            <Box maxW="28">
              <Text mb="0" textStyle="sm" fontWeight="medium" noOfLines={1}>
                {currentAdmin.name}
              </Text>
            </Box>
          </HStack>
        </Tooltip>
        <MFNavAvatarMenu
          currentAdmin={currentAdmin}
          buttonType={trueDockState ? 'avatar' : 'icon'}
          adminType={adminType}
        />
      </HStack>
    </VStack>
  )
}

MFNavSidebarFooter.propTypes = {
  currentAdmin: mdAdminPropType,
  adminType: PropTypes.oneOf(['Super Admin', 'Client Admin', 'Admin']).isRequired
}
