import { Auth0Provider } from '@auth0/auth0-react'
import { ChakraProvider } from '@chakra-ui/react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import App from './App'
import { mfChakraTheme } from './configs/mfChakraTheme'
import { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_REDIRECT_URI } from './Constants'
import { MFAdminAppInitializerProvider } from './contexts/MFAdminAppInitializer'
import { store } from './redux/storeConfig/store'
import { ThemeContext } from './utility/context/ThemeColors'
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import './index.css'

if (module.hot) {
  module.hot.accept()
}

const auth0Domain = REACT_APP_AUTH0_DOMAIN
const auth0ClientId = REACT_APP_AUTH0_CLIENT_ID
const auth0RedirectUri = REACT_APP_AUTH0_REDIRECT_URI

const appRootContainer = document.getElementById('root')
const root = createRoot(appRootContainer)

root.render(
  <BrowserRouter basename="/">
    <ChakraProvider
      theme={mfChakraTheme}
      toastOptions={{
        defaultOptions: {
          position: 'bottom-right',
          variant: 'subtle',
          isClosable: true,
          duration: 5000
        }
      }}
    >
      <MFAdminAppInitializerProvider>
        <Provider store={store}>
          <Auth0Provider domain={auth0Domain} clientId={auth0ClientId} redirect_uri={auth0RedirectUri}>
            <ReduxToastr
              timeOut={3000}
              newestOnTop={false}
              preventDuplicates
              position="top-center"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick
            />
            <ThemeContext>
              <App />
              <ToastContainer newestOnTop />
            </ThemeContext>
          </Auth0Provider>
        </Provider>
      </MFAdminAppInitializerProvider>
    </ChakraProvider>
  </BrowserRouter>
)
