import { css } from '@emotion/react'
import { Component } from 'react'

// First way to import
import { ClipLoader } from 'react-spinners'
import './style.css'

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #36d7b7;
`

class Spinner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  render() {
    return (
      <div className="sweet-loading">
        <ClipLoader css={override} sizeUnit={'px'} size={100} loading={this.state.loading} />
      </div>
    )
  }
}

export default Spinner

// export for testing
export { Spinner }
