/**
 * Converts an array of RGB values to a CSS `rgb()` string.
 *
 * @param {number[]} arr - An array containing three numbers representing the RGB values.
 *
 * @returns {string} - A string in the format `rgb(r, g, b)` where `r`, `g`, and `b` are the red, green, and blue values.
 */
const toRGB = arr => `rgb(${arr.join(', ')})`

/**
 * Converts an array of RGB values to a CSS `rgba()` string with an alpha value.
 *
 * @param {number[]} arr - An array containing three numbers representing the RGB values.
 * @param {number} alpha - A number representing the alpha value (opacity) from 0 to 1.
 *
 * @returns {string} - A string in the format `rgba(r, g, b, a)` where `r`, `g`, and `b` are the red, green, and blue values, and `a` is the alpha value.
 */
const toRGBA = (arr, alpha) => `rgba(${arr.join(', ')}, ${alpha})`

/**
 * RGB values representing the brand's black color.
 *
 * @type {number[]}
 */
const MFBlack = [30, 29, 43]

/**
 * RGB values representing the brand's white color.
 *
 * @type {number[]}
 */
const MFWhite = [253, 254, 255]

/**
 * RGB values representing the brand's light surface color.
 *
 * @type {number[]}
 */
const MFSurfaceLight = [251, 250, 255]

/**
 * A set of RGB values representing different shades of the brand's neutral color palette.
 *
 * The keys correspond to the shade number, with lower numbers indicating lighter shades and higher numbers indicating darker shades.
 *
 * @type {Object.<number, number[]>}
 */
const MFNeutral = {
  50: [231, 230, 237],
  100: [219, 218, 227],
  200: [193, 192, 204],
  300: [168, 166, 178],
  400: [140, 138, 150],
  500: [109, 108, 120],
  600: [83, 82, 94],
  700: [64, 63, 74],
  800: [49, 48, 56],
  900: [38, 37, 43]
}

/**
 * A set of RGB values representing different shades of the brand's primary color palette.
 *
 * The keys correspond to the shade number, with lower numbers indicating lighter shades and higher numbers indicating darker shades.
 *
 * @type {Object.<number, number[]>}
 */
const MFPrimary = {
  50: [241, 240, 255],
  100: [224, 224, 255],
  200: [199, 199, 255],
  300: [144, 133, 255],
  400: [118, 96, 255],
  500: [88, 48, 247],
  600: [74, 30, 227],
  700: [61, 24, 191],
  800: [52, 22, 156],
  900: [39, 14, 110]
}

/**
 * A set of RGB values representing different shades of the brand's blue color palette.
 *
 * The keys correspond to the shade number, with lower numbers indicating lighter shades and higher numbers indicating darker shades.
 *
 * @type {Object.<number, number[]>}
 */
const MFBlue = {
  50: [239, 247, 255],
  100: [219, 237, 254],
  200: [190, 225, 255],
  300: [146, 206, 254],
  400: [95, 179, 251],
  500: [57, 147, 248],
  600: [35, 116, 237],
  700: [27, 94, 218],
  800: [29, 77, 178],
  900: [29, 68, 139]
}

/**
 * A set of RGB values representing different shades of the brand's cyan color palette.
 *
 * The keys correspond to the shade number, with lower numbers indicating lighter shades and higher numbers indicating darker shades.
 *
 * @type {Object.<number, number[]>}
 */
const MFCyan = {
  50: [234, 255, 252],
  100: [203, 255, 250],
  200: [158, 255, 249],
  300: [91, 255, 248],
  400: [0, 249, 250],
  500: [0, 223, 229],
  600: [0, 179, 192],
  700: [3, 142, 155],
  800: [13, 112, 125],
  900: [16, 92, 105]
}

/**
 * The `mfColors` object contains the brand's color palette, including RGB and RGBA values for black, white, neutral, and primary colors.
 *
 * - `black`: The brand's black color in `rgb()` format.
 * - `white`: The brand's white color in `rgb()` format.
 * - `blackAlpha`: An object containing various alpha (transparency) levels of the brand's black color.
 * - `whiteAlpha`: An object containing various alpha levels of the brand's white color.
 * - `neutral`: An object containing different shades of the brand's neutral color palette in `rgb()` format.
 * - `neutralAlpha`: An object containing various alpha levels of the brand's neutral colors.
 * - `primary`: An object containing different shades of the brand's primary color palette in `rgb()` format.
 * - `primaryAlpha`: An object containing various alpha levels of the brand's primary colors.
 *
 * @type {Object}
 */
export const mfColors = {
  black: toRGB(MFBlack),
  white: toRGB(MFWhite),
  lightSurface: toRGB(MFSurfaceLight),

  blackAlpha: {
    50: toRGBA(MFBlack, 0.04),
    100: toRGBA(MFBlack, 0.06),
    200: toRGBA(MFBlack, 0.08),
    300: toRGBA(MFBlack, 0.16),
    400: toRGBA(MFBlack, 0.24),
    500: toRGBA(MFBlack, 0.36),
    600: toRGBA(MFBlack, 0.48),
    700: toRGBA(MFBlack, 0.64),
    800: toRGBA(MFBlack, 0.8),
    900: toRGBA(MFBlack, 0.92)
  },

  whiteAlpha: {
    50: toRGBA(MFWhite, 0.04),
    100: toRGBA(MFWhite, 0.06),
    200: toRGBA(MFWhite, 0.08),
    300: toRGBA(MFWhite, 0.16),
    400: toRGBA(MFWhite, 0.24),
    500: toRGBA(MFWhite, 0.36),
    600: toRGBA(MFWhite, 0.48),
    700: toRGBA(MFWhite, 0.64),
    800: toRGBA(MFWhite, 0.8),
    900: toRGBA(MFWhite, 0.92)
  },

  neutral: {
    50: toRGB(MFNeutral[50]),
    100: toRGB(MFNeutral[100]),
    200: toRGB(MFNeutral[200]),
    300: toRGB(MFNeutral[300]),
    400: toRGB(MFNeutral[400]),
    500: toRGB(MFNeutral[500]),
    600: toRGB(MFNeutral[600]),
    700: toRGB(MFNeutral[700]),
    800: toRGB(MFNeutral[800]),
    900: toRGB(MFNeutral[900])
  },

  neutralAlpha: {
    50: toRGBA(MFNeutral[50], 0.04),
    100: toRGBA(MFNeutral[100], 0.06),
    200: toRGBA(MFNeutral[200], 0.08),
    300: toRGBA(MFNeutral[300], 0.16),
    400: toRGBA(MFNeutral[400], 0.24),
    500: toRGBA(MFNeutral[500], 0.36),
    600: toRGBA(MFNeutral[600], 0.48),
    700: toRGBA(MFNeutral[700], 0.64),
    800: toRGBA(MFNeutral[800], 0.8),
    900: toRGBA(MFNeutral[900], 0.92)
  },

  primary: {
    50: toRGB(MFPrimary[50]),
    100: toRGB(MFPrimary[100]),
    200: toRGB(MFPrimary[200]),
    300: toRGB(MFPrimary[300]),
    400: toRGB(MFPrimary[400]),
    500: toRGB(MFPrimary[500]),
    600: toRGB(MFPrimary[600]),
    700: toRGB(MFPrimary[700]),
    800: toRGB(MFPrimary[800]),
    900: toRGB(MFPrimary[900])
  },

  primaryAlpha: {
    50: toRGBA(MFPrimary[50], 0.04),
    100: toRGBA(MFPrimary[100], 0.06),
    200: toRGBA(MFPrimary[200], 0.08),
    300: toRGBA(MFPrimary[300], 0.16),
    400: toRGBA(MFPrimary[400], 0.24),
    500: toRGBA(MFPrimary[500], 0.36),
    600: toRGBA(MFPrimary[600], 0.48),
    700: toRGBA(MFPrimary[700], 0.64),
    800: toRGBA(MFPrimary[800], 0.8),
    900: toRGBA(MFPrimary[900], 0.92)
  },

  blue: {
    50: toRGB(MFBlue[50]),
    100: toRGB(MFBlue[100]),
    200: toRGB(MFBlue[200]),
    300: toRGB(MFBlue[300]),
    400: toRGB(MFBlue[400]),
    500: toRGB(MFBlue[500]),
    600: toRGB(MFBlue[600]),
    700: toRGB(MFBlue[700]),
    800: toRGB(MFBlue[800]),
    900: toRGB(MFBlue[900])
  },

  blueAlpha: {
    50: toRGBA(MFBlue[50], 0.04),
    100: toRGBA(MFBlue[100], 0.06),
    200: toRGBA(MFBlue[200], 0.08),
    300: toRGBA(MFBlue[300], 0.16),
    400: toRGBA(MFBlue[400], 0.24),
    500: toRGBA(MFBlue[500], 0.36),
    600: toRGBA(MFBlue[600], 0.48),
    700: toRGBA(MFBlue[700], 0.64),
    800: toRGBA(MFBlue[800], 0.8),
    900: toRGBA(MFBlue[900], 0.92)
  },

  cyan: {
    50: toRGB(MFCyan[50]),
    100: toRGB(MFCyan[100]),
    200: toRGB(MFCyan[200]),
    300: toRGB(MFCyan[300]),
    400: toRGB(MFCyan[400]),
    500: toRGB(MFCyan[500]),
    600: toRGB(MFCyan[600]),
    700: toRGB(MFCyan[700]),
    800: toRGB(MFCyan[800]),
    900: toRGB(MFCyan[900])
  },

  cyanAlpha: {
    50: toRGBA(MFCyan[50], 0.04),
    100: toRGBA(MFCyan[100], 0.06),
    200: toRGBA(MFCyan[200], 0.08),
    300: toRGBA(MFCyan[300], 0.16),
    400: toRGBA(MFCyan[400], 0.24),
    500: toRGBA(MFCyan[500], 0.36),
    600: toRGBA(MFCyan[600], 0.48),
    700: toRGBA(MFCyan[700], 0.64),
    800: toRGBA(MFCyan[800], 0.8),
    900: toRGBA(MFCyan[900], 0.92)
  }
}
