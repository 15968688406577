import { chakra, Box, IconButton } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

/**
 * Custom styled bar component for the navigation toggle icon.
 */
const Bar = chakra('span', {
  baseStyle: {
    display: 'block',
    position: 'absolute',
    width: '1.25rem',
    height: '0.125rem',
    borderRadius: 'full',
    backgroundColor: 'currentcolor',
    marginX: 'auto',
    insetStart: '0.125rem',
    transition: 'all 0.12s'
  }
})

/**
 * MFNavToggleIcon component to render the toggle icon.
 *
 * @param {object} props - The properties for the component.
 * @param {boolean} props.active - Determines the active state of the icon.
 * @returns {JSX.Element} The toggle icon component.
 */
const MFNavToggleIcon = ({ active }) => {
  return (
    <Box
      color="fg.muted"
      className="group"
      data-active={active ? '' : undefined}
      as="span"
      display="block"
      width="1.5rem"
      height="1.5rem"
      position="relative"
      aria-hidden
      pointerEvents="none"
    >
      <Bar top="0.4375rem" _groupActive={{ top: '0.6875rem', transform: 'rotate(45deg)' }} />
      <Bar bottom="0.4375rem" _groupActive={{ bottom: '0.6875rem', transform: 'rotate(-45deg)' }} />
    </Box>
  )
}

MFNavToggleIcon.propTypes = {
  active: PropTypes.bool.isRequired
}

/**
 * MFNavToggleButton component to render the button that toggles the navigation.
 *
 * @param {object} props - The properties for the component.
 * @param {boolean} props.isOpen - Determines the open state of the drawer.
 * @param {() => void} props.onOpen - Callback function to open the drawer.
 * @param {() => void} props.onClose - Callback function to close the drawer.
 * @returns {JSX.Element} The toggle button component.
 */
export default function MFNavToggleButton({ isOpen, onOpen, onClose, ...iconButtonProps }) {
  const toggleDrawer = useCallback(() => {
    if (isOpen) {
      onClose()
    } else {
      onOpen()
    }
  }, [isOpen, onOpen, onClose])

  return (
    <IconButton
      variant="unstyled"
      display="inline-flex"
      size="xs"
      icon={<MFNavToggleIcon active={isOpen} />}
      zIndex="tooltip"
      onClick={toggleDrawer}
      {...iconButtonProps}
    />
  )
}

MFNavToggleButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}
