import { useAuth0 } from '@auth0/auth0-react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { createSession } from '../../actions/sessions'
import LoadingSpinner from '../general/LoadingSpinner'

const Auth0Callback = ({ createSession }) => {
  const { getIdTokenClaims, user, isAuthenticated } = useAuth0()

  useEffect(() => {
    const fetchAuth0Token = async () => {
      if (isAuthenticated && user) {
        const idTokenClaims = await getIdTokenClaims()
        const idToken = idTokenClaims.__raw

        const userData = {
          auth0Token: {
            token: idToken,
            email: user.email,
            name: user.name,
            uid: user.sub
          }
        }
        await createSession(userData)
      }
    }

    fetchAuth0Token()
  }, [isAuthenticated, user, getIdTokenClaims, createSession])

  return (
    <div>
      <LoadingSpinner />
    </div>
  )
}

Auth0Callback.propTypes = {
  createSession: PropTypes.func.isRequired
}

export default connect(null, { createSession })(Auth0Callback)
