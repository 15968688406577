import { Box, HStack, IconButton, Tooltip } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
// import PropTypes from 'prop-types'

import MFEnvBadge from '../general/MFEnvBadge'
import MFLogo from '../logo'

import { useMFNav } from './MFNavContext'

// const NavHeaderLogo = ({ menuState, skin }) => {
//   return (
//     <HStack maxW="32" spacing="4">
//       {menuState === 'dock' ? (
//         <MFLogo size={23.67} variant="icon" />
//       ) : (
//         <>
//           <MFLogo variant="icon" size={23.67} />
//           <MFLogo variant="text" textFill={skin === 'dark' ? 'white' : 'black'} />
//         </>
//       )}
//     </HStack>
//   )
// }

// NavHeaderLogo.propTypes = {
//   menuState: PropTypes.oneOf(['dock', 'full']),
//   skin: PropTypes.oneOf(['light', 'dark'])
// }

/**
 * MFNavSidebarHeader component to render the header of the sidebar navigation.
 *
 * The `MFNavSidebarHeader` component is responsible for rendering the logo and environment badge
 * in the sidebar header. It includes an icon button to toggle between different states of the
 * sidebar (e.g., docked or full). The component adapts its layout based on the current state
 * of the navigation menu.
 *
 * **Alternative Logo Rendering:**
 * There is an alternative implementation for rendering the logo in the header (`NavHeaderLogo`)
 * that has been commented out. This alternative approach conditionally renders different logos
 * based on the menu state (either `dock` or `full`) and the skin mode (either `light` or `dark`).
 * This implementation can be uncommented and used if a more dynamic logo rendering is required.
 *
 * @returns {JSX.Element} The sidebar header component.
 */
export default function MFNavSidebarHeader() {
  const { skin, menu, SET_MENU_STATE, onClose } = useMFNav()

  const toggleIcon = useMemo(() => {
    if (menu.state === 'dock') return <FontAwesomeIcon icon="fa-kit fa-menu-layout-5line" />
    if (menu.state === 'full') return <FontAwesomeIcon icon="fa-kit fa-menu-layout-2line" />
    return <FontAwesomeIcon icon={['far', 'xmark']} />
  }, [menu.state])

  const handleToggle = useCallback(() => {
    if (menu.state === 'full') SET_MENU_STATE('dock')
    else if (menu.state === 'dock') SET_MENU_STATE('full')
    else onClose()
  }, [menu.state, SET_MENU_STATE, onClose])

  const logoProps = useMemo(
    () => ({
      size: menu.trueDockState ? 23.67 : 125,
      textFill: skin === 'dark' ? 'white' : 'black',
      variant: menu.trueDockState ? 'icon' : 'full'
    }),
    [skin, menu.trueDockState]
  )

  const badgeProps = useMemo(
    () => ({
      expanded: !menu.trueDockState,
      fontSize: 8,
      position: 'absolute',
      bottom: -2,
      left: menu.trueDockState ? 2 : 6
    }),
    [menu.trueDockState]
  )

  return (
    <HStack
      as="header"
      w="full"
      p="3"
      justifyContent={menu.trueDockState ? 'center' : 'space-between'}
      alignItems="center"
    >
      <Box as={NavLink} to="/dashboard" position="relative" px="2">
        {/* <NavHeaderLogo menuState={menu.trueDockState ? 'dock' : 'full'} skin={skin} /> */}
        <MFLogo {...logoProps} />
        <MFEnvBadge {...badgeProps} />
      </Box>
      <Tooltip label={menu.state === 'dock' ? 'Expand Side Bar' : 'Collapse Side Bar'} placement="right">
        <IconButton
          hidden={menu.trueDockState}
          aria-label="Toggle Sidebar"
          icon={toggleIcon}
          size="sm"
          variant="nav"
          minW="auto"
          h="auto"
          onClick={handleToggle}
        />
      </Tooltip>
    </HStack>
  )
}
