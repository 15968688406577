import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  IconButton,
  Link,
  List,
  ListItem
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { useMFNav } from './MFNavContext'

/**
 * Default props for navigation items, applied consistently across different components.
 *
 * @param {Object} param - The object containing navigation item details.
 * @param {string} param.title - The title of the navigation item.
 * @returns {Object} Default properties for a navigation item.
 */
const defaultProps = ({ title }) => ({
  variant: 'nav',
  colorScheme: 'mf.neutral',
  'aria-label': title,
  fontSize: 'sm'
})

// Shared PropTypes across multiple navigation components to ensure consistency.
const sharedPropTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  state: PropTypes.oneOf(['full', 'dock']) /** @default 'full */
}

/**
 * ExternalNavItem component renders an external link navigation item.
 *
 * This component conditionally renders either a full-width button or an icon-only button based on the `state` prop.
 *
 * @param {Object} props - The properties for the component.
 * @param {string} props.id - The unique identifier for the navigation item.
 * @param {string} props.title - The title of the navigation item.
 * @param {React.ReactNode} props.icon - The icon associated with the navigation item.
 * @param {string} props.href - The URL for the external link.
 * @param {'full' | 'dock'} [props.state='full'] - The state of the navigation (full or docked).
 * @returns {JSX.Element} The rendered external navigation item.
 */
const ExternalNavItem = ({ id, title, icon, href, state = 'full' }) =>
  state === 'full' ? (
    <Button {...defaultProps({ title })} key={id} as={Link} leftIcon={icon} size="full" isExternal href={href}>
      {title}
    </Button>
  ) : (
    <IconButton
      {...defaultProps({ title })}
      key={id}
      as={Link}
      icon={icon}
      isExternal
      href={href}
      justifyContent="center"
    />
  )

ExternalNavItem.propTypes = {
  ...sharedPropTypes,
  href: PropTypes.string.isRequired
}

/**
 * InternalNavItemWithoutChildren component renders an internal navigation item without children.
 *
 * It renders either a full-width button or an icon-only button based on the `state` prop.
 * The component uses `NavLink` to handle internal routing within the application.
 *
 * @param {Object} props - The properties for the component.
 * @param {string} props.id - The unique identifier for the navigation item.
 * @param {string} props.title - The title of the navigation item.
 * @param {React.ReactNode} props.icon - The icon associated with the navigation item.
 * @param {string} props.to - The URL for the internal link.
 * @param {'full' | 'dock'} [props.state='full'] - The state of the navigation (full or docked).
 * @returns {JSX.Element} The rendered internal navigation item without children.
 */
const InternalNavItemWithoutChildren = ({ id, title, icon, to, state = 'full' }) => {
  const { pathname } = useLocation()

  if (state !== 'full')
    return (
      <IconButton
        {...defaultProps({ title })}
        key={id}
        as={NavLink}
        aria-current={pathname === to ? 'page' : undefined}
        exact
        icon={icon}
        to={to}
        justifyContent="center"
      />
    )

  return (
    <Button
      {...defaultProps({ title })}
      key={id}
      as={NavLink}
      aria-current={pathname === to ? 'page' : undefined}
      exact
      leftIcon={icon}
      size="full"
      to={to}
    >
      {title}
    </Button>
  )
}

InternalNavItemWithoutChildren.propTypes = {
  ...sharedPropTypes,
  to: PropTypes.string.isRequired
}

/**
 * InternalNavItemWithChildrenButton component renders a button for navigation items that have children.
 *
 * This component handles the logic for showing or hiding children in an accordion style.
 * The button changes appearance based on the `state` and `active` props.
 *
 * @param {Object} props - The properties for the component.
 * @param {string} props.title - The title of the navigation item.
 * @param {React.ReactNode} props.icon - The icon associated with the navigation item.
 * @param {'full' | 'dock'} [props.state='full'] - The state of the navigation (full or docked).
 * @param {boolean} [props.active] - Whether the navigation item is active.
 * @returns {JSX.Element} The rendered button for navigation items with children.
 */
const InternalNavItemWithChildrenButton = ({ title, icon, state = 'full', active }) =>
  state === 'full' ? (
    <AccordionButton
      {...defaultProps({ title })}
      as={Button}
      leftIcon={icon}
      size="full"
      sx={
        active
          ? {
              bg: 'mf.primary.50 !important',
              color: 'mf.primary.500 !important',

              _hover: {
                bg: 'mf.primary.50 !important',
                color: 'mf.primary.500 !important'
              },

              _dark: {
                bg: 'mf.primaryAlpha.400 !important',
                color: 'mf.primary.100 !important',

                _hover: {
                  bg: 'mf.primaryAlpha.400 !important',
                  color: 'mf.primary.100 !important'
                }
              }
            }
          : {}
      }
    >
      {title}
      <AccordionIcon ml="auto" hidden={state !== 'full'} color={'mf.neutral.300'} _dark={{ color: 'mf.neutral.600' }} />
    </AccordionButton>
  ) : (
    <IconButton
      {...defaultProps({ title })}
      icon={icon}
      sx={active ? { bg: 'mf.primary.50', color: 'mf.primary.500 !important' } : {}}
      _dark={
        active
          ? { bg: 'mf.primaryAlpha.300', color: 'mf.primary.200 !important' }
          : { color: 'mf.neutral.100 !important' }
      }
      justifyContent="center"
    />
  )

InternalNavItemWithChildrenButton.propTypes = {
  ...sharedPropTypes,
  active: PropTypes.bool
}

/**
 * InternalNavItemWithChildren component renders a navigation item that has nested children.
 *
 * This component uses an accordion to handle nested navigation items, with logic to determine if the
 * item or its children should be expanded by default.
 *
 * @param {Object} props - The properties for the component.
 * @param {string} props.id - The unique identifier for the navigation item.
 * @param {string} props.title - The title of the navigation item.
 * @param {React.ReactNode} props.icon - The icon associated with the navigation item.
 * @param {'full' | 'dock'} [props.state='full'] - The state of the navigation (full or docked).
 * @param {Array<Object>} props.children - The nested children navigation items.
 * @returns {JSX.Element} The rendered navigation item with children.
 */
const InternalNavItemWithChildren = ({ id, title, icon, state = 'full', children }) => {
  const { pathname } = useLocation()
  const { SET_CURRENTLY_EXPANDED_ITEM, currentlyExpandedItem } = useMFNav()

  const { parentExpandedByDefault, childExpandedByDefault } = useMemo(() => {
    const childrenPaths = children.map(child => child.navLink)
    const grandChildrenPaths = children
      .map(child => (child.children || []).map(grandChild => grandChild.navLink))
      .flat()

    const parentExpandedByDefault = pathname !== '/' && [...childrenPaths, ...grandChildrenPaths].includes(pathname)
    const childExpandedByDefault = pathname !== '/' && grandChildrenPaths.includes(pathname)

    return { parentExpandedByDefault, childExpandedByDefault }
  }, [children, pathname])

  useEffect(() => {
    if (parentExpandedByDefault) SET_CURRENTLY_EXPANDED_ITEM(id)
  }, [SET_CURRENTLY_EXPANDED_ITEM, id, parentExpandedByDefault])

  const handleChange = useCallback(
    i => {
      if (i === 0) SET_CURRENTLY_EXPANDED_ITEM(id)
      else SET_CURRENTLY_EXPANDED_ITEM(null)
    },
    [SET_CURRENTLY_EXPANDED_ITEM, id]
  )

  return (
    <Accordion key={id} allowToggle index={currentlyExpandedItem === id ? [0] : [-1]} onChange={handleChange} w="full">
      <AccordionItem border="none">
        <InternalNavItemWithChildrenButton
          title={title}
          icon={icon}
          state={state}
          active={
            (state === 'dock' && parentExpandedByDefault) ||
            (state === 'full' && parentExpandedByDefault && currentlyExpandedItem !== id)
          }
        />
        {state === 'full' && (
          <AccordionPanel py="0" px="2">
            <List mb="0">
              {children.map(child => (
                <ListItem key={child.id}>
                  {child.children && child.children.length > 0 ? (
                    <Accordion allowToggle defaultIndex={childExpandedByDefault ? [0] : [-1]}>
                      <AccordionItem border="none">
                        <InternalNavItemWithChildrenButton title={child.title} icon={child.icon} />
                        <AccordionPanel py="0" px="2">
                          <List mb="0">
                            {child.children.map(grandChild => (
                              <ListItem key={grandChild.id}>
                                <InternalNavItemWithoutChildren
                                  id={grandChild.id}
                                  title={grandChild.title}
                                  icon={grandChild.icon}
                                  to={grandChild.navLink}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  ) : (
                    <InternalNavItemWithoutChildren
                      id={child.id}
                      title={child.title}
                      icon={child.icon}
                      to={child.navLink}
                    />
                  )}
                </ListItem>
              ))}
            </List>
          </AccordionPanel>
        )}
      </AccordionItem>
    </Accordion>
  )
}

InternalNavItemWithChildren.propTypes = {
  ...sharedPropTypes,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      ...sharedPropTypes,
      navLink: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          ...sharedPropTypes,
          navLink: PropTypes.string.isRequired
        })
      )
    })
  )
}

/**
 * MFNavNavItem component determines the appropriate type of navigation item to render.
 *
 * Based on the provided props, this component will render either an internal or external link, and handle
 * whether the item has nested children that require an accordion structure.
 *
 * @param {Object} props - The properties for the component.
 * @param {string} props.id - The unique identifier for the navigation item.
 * @param {string} props.title - The title of the navigation item.
 * @param {React.ReactNode} props.icon - The icon associated with the navigation item.
 * @param {Object} props.link - The link configuration for the navigation item.
 * @param {Array<Object>} [props.children=[]] - The nested children navigation items.
 * @returns {JSX.Element} The rendered navigation item.
 */
export default function MFNavNavItem({ id, title, icon, link, children = [] }) {
  const {
    menu: { trueDockState }
  } = useMFNav()

  if (children.length > 0)
    return (
      <InternalNavItemWithChildren
        id={id}
        title={title}
        icon={icon}
        state={trueDockState ? 'dock' : 'full'}
        // eslint-disable-next-line react/no-children-prop
        children={children}
      />
    )

  if (link.type === 'external')
    return <ExternalNavItem id={id} title={title} icon={icon} href={link.url} state={trueDockState ? 'dock' : 'full'} />

  return (
    <InternalNavItemWithoutChildren
      id={id}
      title={title}
      icon={icon}
      to={link.url}
      state={trueDockState ? 'dock' : 'full'}
    />
  )
}

MFNavNavItem.propTypes = {
  ...sharedPropTypes,
  link: PropTypes.shape({
    type: PropTypes.oneOf(['internal', 'external']).isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      ...sharedPropTypes,
      navLink: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          ...sharedPropTypes,
          navLink: PropTypes.string.isRequired
        })
      )
    })
  )
}
