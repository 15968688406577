import _ from 'lodash'
import { FETCH_CARRIER_OPTIONS } from '../actions/types'
import { titleizeField } from '../utility/Utils'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CARRIER_OPTIONS: {
      const excludedNames = [
        'bill_to_postal_code',
        'bill_to_account',
        'bill_to_party',
        'bill_to_country_code',
        'dry_ice_weight',
        'dry_ice',
        'collect_on_delivery'
      ]
      const dDPLabel = 'Bill duties and taxes to payor of shipping charges'
      const dDPToolTip = 'DDP (Delivered Duty Paid)'

      const filteredOptions = action.payload?.options
        ?.filter(option => !excludedNames.includes(option.name) && option.default_value !== null)
        ?.map(option => ({
          ...option,
          label: option.name === 'delivered_duty_paid' ? dDPLabel : titleizeField(option.name),
          toolTip: option.name === 'delivered_duty_paid' ? dDPToolTip : null
        }))
      return filteredOptions || []
    }
    default:
      return state
  }
}
