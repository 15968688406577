import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys)

/**
 * Base styles configuration for the `menu` component.
 *
 * This configuration defines the default styles for various parts of the `menu` component,
 * including `MenuList`, `MenuItem`, and `MenuItemOption`. The styles are tailored for both
 * light and dark modes, ensuring consistent and accessible appearance across different themes.
 *
 * - `list`: Styles for the `MenuList` component, which is the container that holds all menu items.
 *   - `py`: Sets the vertical padding for the list.
 *   - `borderRadius`: Applies a medium border radius to the list for rounded corners.
 *   - `border`: Removes any default border from the list.
 *   - `bg`: Sets the background color for the list in light mode.
 *   - `color`: Defines the text color in light mode.
 *   - `_dark`: Provides styles for dark mode, adjusting background and text colors.
 *
 * - `item`: Styles for the `MenuItem` and `MenuItemOption` components, which represent individual menu options.
 *   - `bg`: Sets the background color to transparent for a clean look.
 *   - `color`: Sets the text color for menu items in light mode.
 *   - `_hover`: Styles applied when a menu item is hovered over, changing the background color.
 *   - `_disabled`: Disables pointer events for disabled menu items, making them non-interactive.
 *   - `_focus`: Styles applied when a menu item is focused, removing the outline and changing the background color.
 *   - `_dark`: Provides dark mode styles for menu items, including hover and focus states.
 *
 * @type {Object}
 */
const baseStyle = definePartsStyle({
  list: {
    // Styles for the MenuList component
    py: 2,
    borderRadius: 'md',
    border: 'none',
    bg: 'mf.white',
    color: 'mf.black',
    boxShadow: 'md',

    _dark: {
      bg: 'mf.neutral.900',
      color: 'mf.white'
    }
  },

  item: {
    // Styles for the MenuItem and MenuItemOption components
    bg: 'transparent',
    color: 'mf.neutral.900',

    _hover: {
      bg: 'mf.blackAlpha.50'
    },

    _disabled: {
      pointerEvents: 'none'
    },

    _focus: {
      bg: 'mf.neutral.100',
      outline: 'none'
    },

    _dark: {
      color: 'mf.neutral.100',

      _hover: {
        bg: 'mf.neutral.800'
      },

      _focus: {
        bg: 'mf.neutral.800'
      }
    }
  }
})

/**
 * The theme configuration for the `menu` component.
 *
 * This configuration exports the base styles that will be applied to all `menu` components
 * in the application. Additional styles, variants, or sizes can be added in the future
 * to extend the theme as needed.
 *
 * - `baseStyle`: The default styles applied to all parts of the menu, ensuring consistent
 *   behavior and appearance in both light and dark modes.
 *
 * For more information on theming the `menu` component, visit the [Chakra UI Menu Theming Documentation](https://v2.chakra-ui.com/docs/components/menu/theming).
 *
 * @type {Object}
 */
export const menuTheme = defineMultiStyleConfig({ baseStyle })
