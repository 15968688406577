import { Divider, VStack } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { connect } from 'react-redux'

import { mdAdminPropType } from '../../types/admins'

import MFNavProvider from './MFNavContext'
import MFNavSidebarContainer from './MFNavSidebarContainer'
import MFNavSidebarFooter from './MFNavSidebarFooter'
import MFNavSidebarHeader from './MFNavSidebarHeader'
import MFNavSidebarNav from './MFNavSidebarNav'
import MFNavTopBar from './MFNavTopBar'

/**
 * @typedef {Object} MFNavProps
 * @property {import('../../types/admins').MFAdmin} current_admin - The current admin user.
 * @property {boolean | string[]} permissions - Permissions associated with the admin user.
 */

/**
 * MFNav component to provide navigation and context.
 *
 * The `MFNav` component is responsible for rendering the main navigation structure
 * of the application, including the top bar, sidebar header, navigation, and footer.
 * It uses the `MFNavProvider` to supply necessary context values and connects to the Redux store
 * to manage admin permissions and user information.
 *
 * @param {MFNavProps} props - The properties for the component, including the current admin,
 * permissions, and the list of admin users.
 * @returns {JSX.Element} The rendered MFNav component.
 */
function MFNav({ current_admin: currentAdmin, permissions }) {
  const adminType = useMemo(() => {
    if (currentAdmin.is_super_admin) return 'Super Admin'
    if (currentAdmin.is_client_admin) return 'Client Admin'
    return 'Admin'
  }, [currentAdmin])

  return (
    <MFNavProvider>
      <MFNavTopBar currentAdmin={currentAdmin} adminType={adminType} />
      <MFNavSidebarContainer>
        <VStack w="full" alignItems="center" spacing="0">
          <MFNavSidebarHeader />
          <Divider m="0" />
        </VStack>
        <MFNavSidebarNav currentAdmin={currentAdmin} permissions={permissions} />
        <VStack w="full" alignItems="center" spacing="0">
          <Divider m="0" />
          <MFNavSidebarFooter currentAdmin={currentAdmin} adminType={adminType} />
        </VStack>
      </MFNavSidebarContainer>
    </MFNavProvider>
  )
}

MFNav.propTypes = {
  current_admin: mdAdminPropType,
  permissions: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]).isRequired
}

/**
 * Map Redux state to component props.
 *
 * The `mapStateToProps` function connects the Redux store's state to the component's props.
 * It extracts the array of admin users, the current admin, and permissions, making them
 * available as props to the `MFNav` component.
 *
 * @param {import('../../types/store').MFAdminStore} state - The Redux state.
 * @returns {MFNavProps} The mapped props for the component.
 */
const mapStateToProps = state => {
  return {
    current_admin: state.admins.current_admin,
    permissions: state.permissions
  }
}

/**
 * Connects the `MFNav` component to the Redux store and actions.
 *
 * The `connect` function is used to bind the `mapStateToProps` function and
 * the `fetchAdminPermissions` action creator to the `MFNav` component, allowing
 * it to interact with the Redux store and manage state and side effects.
 *
 * @type {React.ComponentType<MFNavProps>}
 */
export default connect(mapStateToProps, {})(MFNav)
