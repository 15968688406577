import { Box, Container, HStack } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { mdAdminPropType } from '../../types/admins'

import MFEnvBadge from '../general/MFEnvBadge'
import MFLogo from '../logo'

import MFNavAvatarMenu from './MFNavAvatarMenu'
import { useMFNav } from './MFNavContext'
import MFNavToggleButton from './MFNavToggleButton'

/**
 * @typedef {Object} MFNavTopBarProps
 * @property {import('../../types/admins').MFAdmin} currentAdmin - The current admin user.
 */

/**
 * MFNavTopBar component to render the navbar docked at top of the page.
 *
 * The `MFNavTopBar` component is responsible for rendering the navigation bar at the top of the page.
 * It includes the navigation toggle button, logo, environment badge, and the avatar menu for the current admin.
 * The component adapts its layout based on the state of the navigation menu, displaying different elements
 * depending on whether the menu is in a drawer or docked state.
 *
 * @param {MFNavTopBarProps} props - The properties for the component, including the current admin user.
 * @returns {JSX.Element} The rendered navigation top bar component.
 */
export default function MFNavTopBar({ currentAdmin, adminType }) {
  const { menu, skin, isOpen, onOpen, onClose } = useMFNav()

  return (
    <Box as="header" position="fixed" top="0" left="0" w="full" zIndex="modal">
      <Box
        borderBottomWidth="1px"
        borderColor="mf.neutral.50"
        bg="mf.lightSurface"
        _dark={{ bg: 'mf.black', borderColor: 'mf.neutral.700' }}
        position="relative"
      >
        <Container py="3" maxW="full">
          <HStack h="6" justify={menu.state === 'drawer' ? 'space-between' : 'flex-end'}>
            {menu.state === 'drawer' && (
              <HStack justify="start" spacing="8" zIndex="tooltip">
                {menu.state === 'drawer' && <MFNavToggleButton onOpen={onOpen} isOpen={isOpen} onClose={onClose} />}
                <HStack position="relative" justifyContent="space-between" alignItems="end">
                  <MFLogo size={125} textFill={skin === 'dark' ? 'white' : 'black'} />
                  <MFEnvBadge fontSize="8" position="absolute" bottom="-2" left="6" expanded={menu.state !== 'dock'} />
                </HStack>
              </HStack>
            )}
            {menu.state === 'drawer' && !isOpen && (
              <MFNavAvatarMenu currentAdmin={currentAdmin} adminType={adminType} />
            )}
          </HStack>
        </Container>
      </Box>
    </Box>
  )
}

MFNavTopBar.propTypes = {
  currentAdmin: mdAdminPropType,
  adminType: PropTypes.oneOf(['Super Admin', 'Client Admin', 'Admin']).isRequired
}
