import _ from 'lodash';
import { FETCH_CARRIER_RATES_MULTI_BOX } from '../actions/types'
import dollar_price from '../components/general/DollarPrice'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_CARRIER_RATES_MULTI_BOX:
      const rates = action.payload
      const formattedRates = rates.map(carrier => {
        const { estimatedDeliveryDate, shippingCost, otherAmount, serviceType, rateTotal } = carrier
        const deliveryDate = estimatedDeliveryDate
          ? ' (ETA ' + new Date(estimatedDeliveryDate).toLocaleDateString('en-us', { month: 'short', day: 'numeric' })
          : ' (ETA N/A'
        const estimateTotal = dollar_price(parseFloat(rateTotal) * 100)
        return {
          ...carrier,
          serviceType: serviceType + ' ' + deliveryDate + ') - $' + estimateTotal
        }
      })
      return formattedRates.sort((a, b) => a.rateTotal - b.rateTotal)
    case 'CLEAR_CARRIER_RATES_MULTI_BOX':
      return []
    default:
      return state
  }
};
