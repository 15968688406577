import {
  Button,
  Code,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
  ButtonGroup
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import branditRails from '../../api/brandit_rails'
import { handleLogout } from '../../redux/actions/auth'
import { mdAdminPropType } from '../../types/admins'

import MFAvatar from '../MFAvatar'
import { useMFNav } from './MFNavContext'

/**
 * @typedef {Object} MFNavAvatarMenuProps
 * @property {import('../../types/admins').MFAdmin} currentAdmin - The current admin user.
 * @property {'avatar' | 'icon'} [buttonType='avatar'] - The type of button to render.
 * @property {'Super Admin' | 'Client Admin' | 'Admin'} adminType - The type of admin user.
 */

/**
 * MFNavAvatarMenu component to render the navigation avatar menu.
 *
 * @param {MFNavAvatarMenuProps} props - The properties for the component.
 * @returns {JSX.Element} The navigation avatar menu component.
 */
export default function MFNavAvatarMenu({ currentAdmin, buttonType = 'avatar', adminType }) {
  const [selectedPortalId, setSelectedPortalId] = useState(null)
  const [clientModalOpen, setClientModalModalOpen] = useState(false)

  const { skin, SET_SKIN } = useMFNav()

  const dispatch = useDispatch()

  const toast = useToast()

  const handleLogoutButton = useCallback(() => {
    dispatch(handleLogout(currentAdmin))
  }, [currentAdmin, dispatch])

  const skinIcon = useMemo(() => (skin === 'dark' ? 'sun-bright' : 'moon'), [skin])

  const toggleClientModal = useCallback(() => {
    setClientModalModalOpen(prev => !prev)
    setSelectedPortalId(null)
  }, [])

  const ssoLoginPrivateWindow = useCallback(async () => {
    try {
      if (adminType !== 'Client Admin') throw new Error("User is not a 'Client Admin'")

      const {
        user: { id: userId },
        associated_portal_info: associatedPortalInfo
      } = currentAdmin
      let portalId = null

      if (!selectedPortalId && associatedPortalInfo.length > 0) {
        toggleClientModal()
        return null
      }

      if (selectedPortalId !== null) {
        portalId = selectedPortalId
        setClientModalModalOpen(false)
      } else if (associatedPortalInfo.length === 1) portalId = associatedPortalInfo[0].portal_id

      const { data } = await branditRails.get(`/get_sso_url_customer_show/user_id/${userId}/portal_id/${portalId}`)

      if (data.error)
        toast({
          title: 'Error',
          description: (
            <>
              <Code>{data.error}</Code>
              <Text>Please contact administrator.</Text>
            </>
          ),
          status: 'error'
        })
      else if (data.url) window.location.href = data.url
    } catch (err) {
      toast({
        title: 'Error',
        description: (
          <>
            <Code colorScheme="red">{err.message}</Code>
            <Text>Could not log in as customer.</Text>
          </>
        ),
        status: 'error'
      })
    }
  }, [adminType, currentAdmin, selectedPortalId, toast, toggleClientModal])

  const storeNames = useMemo(
    () =>
      currentAdmin.associated_portal_info.map(portal => ({
        label: portal.portal_name,
        value: portal.portal_id
      })) ?? [],
    [currentAdmin.associated_portal_info]
  )

  const UserMenuButton = useMemo(() => {
    if (buttonType === 'avatar')
      return (
        <MenuButton as={Button} variant="link" minW="0" aria-label="Open Menu">
          <MFAvatar userImg={currentAdmin.avatar} adminType={adminType} name={currentAdmin.name} size="xs" />
        </MenuButton>
      )

    return (
      <MenuButton
        as={IconButton}
        variant="nav"
        icon={<FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />}
        aria-label="Open Menu"
        size="sm"
        justifyContent="center"
      />
    )
  }, [buttonType, currentAdmin.avatar, adminType, currentAdmin.name])

  const menuItemProps = ({ icon, isDisabled = false, hidden = false, label, onClick }) => ({
    justifyContent: 'start',
    icon: <FontAwesomeIcon icon={['far', icon]} />,
    isDisabled,
    hidden,
    onClick,
    'aria-label': label,
    children: label
  })

  return (
    <>
      <Menu placement={buttonType === 'avatar' ? 'bottom-start' : 'right'}>
        {UserMenuButton}
        <MenuList>
          <MenuItem flex justifyContent="center" hidden={adminType !== 'Client Admin'}>
            <Button
              colorScheme="mf.primary"
              size="full"
              leftIcon={<FontAwesomeIcon icon={['far', 'shield-keyhole']} />}
              onClick={ssoLoginPrivateWindow}
            >
              Access Store
            </Button>
          </MenuItem>
          <MenuItem
            {...menuItemProps({
              icon: skinIcon,
              label: skin === 'dark' ? 'Light Mode' : 'Dark Mode',
              onClick: () => SET_SKIN(skin === 'dark' ? 'light' : 'dark')
            })}
          />
          <MenuItem
            {...menuItemProps({
              icon: 'user',
              label: 'Profile (coming soon)',
              isDisabled: true
            })}
          />
          <MenuItem
            {...menuItemProps({
              icon: 'gear',
              label: 'Preferences (coming soon)',
              isDisabled: true
            })}
          />
          <MenuItem
            {...menuItemProps({
              icon: 'key',
              label: 'Update Password'
            })}
            as={Link}
            to="/update_password"
            color="mf.neutral.600 !important"
            _dark={{ color: 'mf.neutral.200 !important' }}
          />
          <MenuItem
            {...menuItemProps({
              icon: 'file-invoice-dollar',
              label: 'Billing (coming soon)',
              isDisabled: true
            })}
          />
          <MenuItem
            {...menuItemProps({
              icon: 'right-from-bracket',
              label: 'Sign Out',
              onClick: handleLogoutButton
            })}
          />
        </MenuList>
      </Menu>
      <Modal isOpen={clientModalOpen} onClose={toggleClientModal} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FontAwesomeIcon icon={['far', 'shield-keyhole']} /> Select Store
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text>There are multiple stores associated with your account. Please select one to continue.</Text>
            <Select
              placeholder="Select Store"
              defaultValue={selectedPortalId ? storeNames.find(store => store.value === selectedPortalId) : undefined}
              onChange={e => setSelectedPortalId(e.target.value)}
            >
              {storeNames.map(storeName => (
                <option key={storeName.value} value={storeName.value}>
                  {storeName.label}
                </option>
              ))}
            </Select>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button colorScheme="mf.neutral" onClick={toggleClientModal}>
                Cancel
              </Button>
              <Button colorScheme="mf.primary" onClick={ssoLoginPrivateWindow}>
                Next
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

MFNavAvatarMenu.propTypes = {
  currentAdmin: mdAdminPropType,
  buttonType: PropTypes.oneOf(['avatar', 'icon']) /** @default 'avatar' */,
  adminType: PropTypes.oneOf(['Super Admin', 'Client Admin', 'Admin']).isRequired
}
