import { Button, FormControl, FormErrorMessage, Heading, Input, Stack, Text, useColorMode } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { resetAdminPassword, verifyPasswordToken } from '../../actions' // Adjust to your specific action
import { useMFAdminAppInitializer } from '../../contexts/MFAdminAppInitializer'
import MFLogo from '../logo'

const ResetPasswordForm = props => {
  const [authtoken] = useState(props.match.params.token)

  const {
    state: {
      layout: { skin }
    }
  } = useMFAdminAppInitializer()

  const { colorMode } = useColorMode()

  const { register, handleSubmit, formState, getValues } = useForm({ mode: 'onBlur' })

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(verifyPasswordToken(authtoken))
  }, [dispatch, authtoken])

  const charLength = { regex: '15,30', field: 'between 15 - 30' }
  const passwordRegex = new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*_-])(?=.*?[0-9]).{${charLength.regex}}$`)

  const onSubmit = data => {
    const { newPassword, confirmPassword } = data

    // Check if the newPassword matches the confirmPassword and exit if they don't match
    if (newPassword !== confirmPassword) {
      toastr.error('Error', 'Your new password and confirm new password do not match')
      return
    }

    dispatch(resetAdminPassword(authtoken, newPassword))
  }

  return (
    <Stack>
      <Stack spacing="8">
        <Stack spacing="6" align="center">
          <MFLogo textFill={skin === 'light' ? 'black' : 'white'} size={280} />
          <Stack spacing="3" textAlign="center">
            <Heading size="xs" mb="0" color="mf.black" _dark={{ color: 'mf.white' }}>
              Reset your password
            </Heading>
            <Text mb="0" color="mf.neutral.600" _dark={{ color: 'mf.neutral.200' }}>
              Enter your new password
            </Text>
          </Stack>
        </Stack>
        <Stack spacing="6">
          <Stack as="form" spacing="4" onSubmit={handleSubmit(onSubmit)}>
            <FormControl isRequired isInvalid={formState.errors.newPassword}>
              <Input
                type="password"
                placeholder="Enter your new password"
                {...register('newPassword', {
                  required: 'Password is required',
                  validate: v =>
                    passwordRegex.test(v) ||
                    `Password must be ${charLength.field} characters and include: 1 lower case, 1 Upper case, 1 numeric, and 1 special character`
                })}
              />
              <FormErrorMessage>{formState.errors?.newPassword?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={formState.errors.confirmPassword}>
              <Input
                type="password"
                placeholder="Confirm new password"
                {...register('confirmPassword', {
                  required: 'Password is required',
                  validate: v => {
                    const { newPassword } = getValues()

                    return v === newPassword || 'Passwords must match'
                  }
                })}
              />
              <FormErrorMessage>{formState.errors?.confirmPassword?.message}</FormErrorMessage>
            </FormControl>
            <Button type="submit" colorScheme={colorMode === 'light' ? 'mf.black' : 'mf.primary'}>
              Update Password
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ResetPasswordForm
