import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useMFAdminAppInitializer } from '../../contexts/MFAdminAppInitializer'

const FeaturebaseSdk = props => {
  const {
    state: {
      layout: { skin }
    }
  } = useMFAdminAppInitializer()

  useEffect(() => {
    if (!props.current_admin.name) {
      return
    }
    const script = document.createElement('script')
    script.src = 'https://do.featurebase.app/js/sdk.js'
    script.id = 'featurebase-sdk'
    document.body.appendChild(script)

    script.onload = () => {
      const win = window

      if (typeof win.Featurebase !== 'function') {
        win.Featurebase = function () {
          ;(win.Featurebase.q = win.Featurebase.q || []).push(arguments)
        }
      }

      win.Featurebase('initialize_changelog_widget', {
        organization: 'marketfuel',
        placement: 'top',
        theme: skin ?? 'light',
        fullscreenPopup: false,
        locale: 'en',
        usersName: props.current_admin.name,
        jwtToken: props.current_admin.featurebase_authentication_token
      })
    }
    return () => {
      document.body.removeChild(script)
    }
  }, [props.current_admin, skin])

  return null
}
const mapStateToProps = (state, ownProps) => {
  return {
    current_admin: state.admins.current_admin
  }
}

export default connect(mapStateToProps)(FeaturebaseSdk)
