import { useEffect } from 'react'
import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'

import { fetchCurrentAdmin, setLoading, fetchAdminPermissions, getOrderErrorsCount } from './actions'
import { fetchCurrentAgency, redirectToLogin } from './actions/agencies'
import { setSessionToken } from './actions/sessions'
import FeaturebaseSdk from './components/general/FeaturebaseSdk'
import HandlePackageJsonVersion from './components/general/HandlePackageJsonVersion'
import MfSweetAlert from './components/general/MfSweetAlert'
import useEE from './components/hooks/easterEgg'
import { handleLogout } from './redux/actions/auth'
import MFRouter from './router/MFRouter'

HandlePackageJsonVersion()

const App = ({
  fetchCurrentAdmin,
  fetchCurrentAgency,
  fetchAdminPermissions,
  currentAdmin,
  getOrderErrorsCount,
  currentAgency,
  loading,
  handleLogout,
  session,
  setSessionToken,
  setLoading
}) => {
  useEE()
  // useVersionCheck()

  // when the app loads, if token is present in storage, set it to redux session
  useEffect(() => {
    // ignore if we're attempting SSO login
    if (window.location.pathname.includes('sso-log-in') || window.location.pathname.includes('auth0')) {
      return
    }
    const storageToken = sessionStorage.getItem('agencyAuthToken')
    if (storageToken) {
      setSessionToken(storageToken)
    } else {
      setLoading('session', false)
      setLoading('admin', false)
    }
  }, [])

  // when session is set, fetch admin details
  useEffect(() => {
    if (!currentAdmin.id) {
      if (session) {
        setLoading('admin', true)

        fetchCurrentAdmin().then(() => {
          setLoading('admin', false)
        })
        // .catch(err => {
        //   if (!window.pathname?.includes('auth0') && !window.pathname?.includes('sso-log-in')) {
        //     console.error(err)
        //     handleLogout().then(() => {
        //       redirectToLogin()
        //     })
        //   }
        // })
      } else {
        // if there's no session and no loading is happening, redirect to login
        if (!loading.admin && !loading.session) {
          redirectToLogin()
        }
      }
    }
  }, [session, loading.session])

  // fetch agency details after currentAdmin is set
  useEffect(() => {
    if (currentAdmin.id && loading.admin) {
      setLoading('admin', false)
    }
    if (currentAdmin.id && !currentAgency && session) {
      // auth token present on admin, fetching current agency
      fetchCurrentAgency().catch(() => {
        console.error('Failed to fetch agency')
        // if fetching the agency fails here, something went wrong. We need the agency
        handleLogout()
      })
    }
  }, [currentAdmin.id])

  // fetch permissions and order errors count after currentAdmin is set
  useEffect(() => {
    if (currentAdmin.id) {
      fetchAdminPermissions(currentAdmin.id)
      if (currentAdmin.is_super_admin) getOrderErrorsCount()
    }
  }, [currentAdmin.id, currentAdmin.is_super_admin, fetchAdminPermissions, getOrderErrorsCount])

  const shouldShowLoading = loading

  return (
    <>
      <FeaturebaseSdk />
      <MfSweetAlert />
      <MFRouter />
    </>
  )
}

const mapStateToProps = state => {
  return {
    currentAdmin: state.admins.current_admin,
    themeMode: state.layout.themeMode,
    loading: state.loading,
    currentAgency: state.agencies.current_agency,
    session: state.session
  }
}

export default connect(mapStateToProps, {
  fetchCurrentAdmin,
  fetchAdminPermissions,
  fetchCurrentAgency,
  getOrderErrorsCount,
  handleLogout,
  setSessionToken,
  setLoading
})(App)
