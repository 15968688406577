import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

/**
 * Generates styles for the 'link' variant of the button component.
 *
 * This variant customizes the focus state of the button. It adjusts the color based on
 * the `colorScheme` prop provided by Chakra UI and applies different styles when the
 * application is in dark mode.
 *
 * @param {import('@chakra-ui/react').StyleFunctionProps} props - The props object provided by Chakra UI, which includes
 * various properties like `colorScheme`, `isDisabled`, `isFullWidth`, `isLoading`, `theme`,
 * `variant`, `size`, and `colorMode`.
 *
 * @returns {Object} - The style object for the 'link' variant.
 */
const link = props => ({
  _focus: {
    outline: 'none',
    color: `${props.colorScheme}.500 !important`
  },
  _dark: {
    _focus: {
      color: `${props.colorScheme}.300 !important`
    }
  }
})

const solid = props => {
  const notShaded = props.colorScheme !== 'mf.black' && props.colorScheme !== 'mf.white'
  return {
    bg: notShaded ? `${props.colorScheme}.400` : props.colorScheme,
    color: props.colorScheme === 'mf.white' ? 'mf.black' : 'mf.white',
    _hover: {
      bg: notShaded
        ? `${props.colorScheme}.500`
        : props.colorScheme === 'mf.black'
          ? 'mf.neutral.800'
          : 'mf.neutral.100'
    }
  }
}

const outline = props => ({
  borderColor: `${props.colorScheme}.50`,
  color: `${props.colorScheme}.600`,
  _hover: {
    bg: `${props.colorScheme}.50`
  },

  _dark: {
    borderColor: `${props.colorScheme}.800`,
    color: `${props.colorScheme}.100`,

    _hover: {
      bg: `${props.colorScheme}.800`
    }
  }
})

/**
 * Styles configuration for the 'nav' variant of the button component.
 *
 * This variant is tailored for navigation buttons within the application. It defines
 * spacing, color, and interaction states such as focus, hover, and active. The styles
 * also account for different appearances when the application is in dark mode.
 *
 * @type {Object}
 */
const nav = {
  h: '9',
  py: '1 !important',
  px: '2 !important',
  justifyContent: 'start',
  gap: '2',
  color: 'mf.neutral.800 !important',
  fontWeight: '500',
  textDecoration: 'none !important',

  _focus: {
    outline: 'none'
  },

  _expanded: {
    color: 'mf.primary.500 !important',

    _hover: {
      bg: 'none !important'
    }
  },

  _hover: {
    bg: 'mf.blackAlpha.50 !important',
    color: 'mf.neutral.800 !important'
  },

  _activeLink: {
    bg: 'mf.primary.50 !important',
    color: 'mf.primary.500 !important'
  },

  _disabled: {
    pointerEvents: 'none'
  },

  _dark: {
    color: 'mf.white !important',

    _focus: {
      outline: 'none'
    },

    _hover: {
      bg: 'mf.whiteAlpha.50 !important',
      color: 'mf.white !important'
    },

    _activeLink: {
      bg: 'mf.primaryAlpha.400 !important',
      color: 'mf.primary.100 !important'
    },

    _expanded: {
      color: 'mf.primary.200 !important',

      _hover: {
        bg: 'none !important'
      }
    }
  }
}

/**
 * Defines a full-width button style.
 *
 * This style is used when a button should occupy the full width of its parent container,
 * with specific height, font size, and padding.
 *
 * @type {Object}
 */
const fullWidth = defineStyle({
  w: 'full',
  height: '10',
  fontSize: 'md',
  px: '4'
})

/**
 * The theme configuration for the button component.
 *
 * This configuration includes custom sizes and variants that can be applied
 * across the application for consistent button styling.
 *
 * - Sizes:
 *   - `full`: Applies the `fullWidth` style for buttons.
 *
 * - Variants:
 *   - `link`: Applies the `link` style variant.
 *   - `nav`: Applies the `nav` style variant.
 *
 * For more information on theming the `button` component, visit the [Chakra UI Button Theming Documentation](https://v2.chakra-ui.com/docs/components/button/theming).
 *
 * @type {Object}
 */
export const buttonTheme = defineStyleConfig({
  sizes: { full: fullWidth },
  variants: { link, solid, outline, nav }
})
