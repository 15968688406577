import { drawerAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

/**
 * Styles configuration for the 'nav' variant of the drawer component.
 *
 * This variant is designed for navigation drawers. It customizes the appearance
 * of the drawer dialog and dialog container, including different color schemes
 * for light and dark modes.
 *
 * - `dialog`: The main dialog of the drawer, where most content resides. It includes styles
 *   for pointer events, background color, border, and shadow. The `_dark` mode specifies
 *   how the dialog should appear in dark mode.
 * - `dialogContainer`: The container for the dialog, styled with pointer events disabled to
 *   ensure the container itself isn't interactive.
 *
 * @type {Object}
 */
const nav = definePartsStyle({
  parts: ['dialog', 'dialogContainer'],
  dialog: {
    pointerEvents: 'auto',
    borderRadius: 0,

    // light mode colors
    bg: `mf.lightSurface`,
    borderRight: '1px solid',
    borderColor: 'mf.neutral.50',
    boxShadow: 'none',

    // dark mode colors
    _dark: {
      bg: `mf.black`,
      color: 'mf.white',
      borderColor: 'mf.neutral.700'
    }
  },
  dialogContainer: {
    pointerEvents: 'none'
  }
})

/**
 * Base styles configuration for the drawer component.
 *
 * This base style is applied to all variants of the drawer unless explicitly overridden.
 * It includes general styles for the dialog and overlay.
 *
 * - `dialog`: The main dialog of the drawer, styled with rounded corners and a white background.
 * - `overlay`: The overlay that appears behind the dialog, styled with a semi-transparent
 *   black background and a blur effect for added emphasis.
 *
 * @type {Object}
 */
const baseStyle = definePartsStyle({
  dialog: {
    borderRadius: 'md',
    bg: 'mf.white'
  },

  overlay: {
    bg: 'mf.blackAlpha.300',
    backdropFilter: 'blur(2.4px) saturate(90%)'
  }
})

/**
 * The theme configuration for the drawer component.
 *
 * This configuration includes the base styles, custom variants like `nav`,
 * and potential sizes that can be applied across the application for consistent
 * drawer styling.
 *
 * - `baseStyle`: The default styles applied to all drawers.
 * - `variants`: Custom variants like `nav` that define specific styles for different use cases.
 * - `sizes`: Custom sizes for the drawer can be defined here (e.g., full width, specific breakpoints).
 *
 * For more information on theming the `drawer` component, visit the [Chakra UI Drawer Theming Documentation](https://v2.chakra-ui.com/docs/components/drawer/theming).
 *
 * @type {Object}
 */
export const drawerTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { nav },
  sizes: {
    // nav: { base: { w: 'full', md: '64' }}
  }
})
