import PropTypes from 'prop-types'

/**
 * @typedef {Object} MFAdminAvatar
 * @property {string|null} url
 * @property {Object} small
 * @property {string|null} small.url
 * @property {Object} medium
 * @property {string|null} medium.url
 * @property {Object} initial
 * @property {string|null} initial.url
 */

/**
 * @typedef {Object} MFAgencyAdmin
 * @property {number} id
 * @property {number} admin_id
 * @property {number} agency_id
 * @property {boolean} is_default
 * @property {string} created_at
 * @property {string} updated_at
 */

/**
 * @typedef {Object} MFOrganizationAdmin
 * @property {number} id
 * @property {number} organization_id
 * @property {string} created_at
 * @property {string} updated_at
 * @property {number} admin_id
 * @property {boolean} is_default
 */

/**
 * @typedef {Object} MFAdmin
 * @property {number} id
 * @property {string} name
 * @property {string} email
 * @property {boolean|null} is_client_admin
 * @property {number|null} client_id
 * @property {boolean} is_account_manager
 * @property {boolean} show_ids
 * @property {boolean} admin_has_users
 * @property {boolean} linked_to_user
 * @property {number[]} role_ids
 * @property {string} authentication_token
 * @property {MFAdminAvatar} avatar
 * @property {boolean} enable_two_factor_auth
 * @property {boolean} has_api_access
 * @property {boolean} is_active
 * @property {string|null} mobile_for_2fa
 * @property {string|null} timezone
 * @property {string} last_log_attempt_ip
 * @property {string[]} roles_names
 * @property {string|null} verification_code
 * @property {boolean} is_super_admin
 * @property {Object[]} associated_portal_info
 * @property {Object[]} admin_clients
 * @property {MFAgencyAdmin[]} agency_admins
 * @property {MFOrganizationAdmin[]} organization_admins
 * @property {Object[]} client_account_managers
 * @property {Object|null} user
 * @property {string} featurebase_authentication_token
 */

export const mdAdminPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  is_client_admin: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  client_id: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  is_account_manager: PropTypes.bool.isRequired,
  show_ids: PropTypes.bool.isRequired,
  admin_has_users: PropTypes.bool.isRequired,
  linked_to_user: PropTypes.bool.isRequired,
  role_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  authentication_token: PropTypes.string.isRequired,
  avatar: PropTypes.object.isRequired,
  enable_two_factor_auth: PropTypes.bool.isRequired,
  has_api_access: PropTypes.bool.isRequired,
  is_active: PropTypes.bool.isRequired,
  mobile_for_2fa: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  timezone: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  last_log_attempt_ip: PropTypes.string.isRequired,
  roles_names: PropTypes.arrayOf(PropTypes.string).isRequired,
  verification_code: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  is_super_admin: PropTypes.bool.isRequired,
  associated_portal_info: PropTypes.arrayOf(PropTypes.object).isRequired,
  admin_clients: PropTypes.arrayOf(PropTypes.object).isRequired,
  agency_admins: PropTypes.arrayOf(PropTypes.object).isRequired,
  organization_admins: PropTypes.arrayOf(PropTypes.object).isRequired,
  client_account_managers: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.object
}).isRequired

/**
 * @typedef {Object} MFAdminResponse
 * @property {MFAdmin[]} admins
 * @property {MFAdmin} current_admin
 * @property {boolean} updatingPassword
 */
