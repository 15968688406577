import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys)

const outline = definePartsStyle({
  field: {
    border: '1px solid',
    borderColor: 'mf.neutral.50',
    color: 'mf.neutral.600',

    _dark: {
      borderColor: 'mf.neutral.800',
      color: 'mf.neutral.100'
    },

    _focusVisible: {
      borderColor: 'mf.primary.500',
      boxShadow: '0 0 0 1px #5440d2'
    }
  }
})

export const inputTheme = defineMultiStyleConfig({ defaultProps: { variant: 'outline' }, variants: { outline } })
