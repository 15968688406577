import { alertAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(alertAnatomy.keys)

const subtle = definePartsStyle(props => ({
  container: {
    bg: `${props.colorScheme}.100`,
    color: 'mf.black',

    _dark: {
      bg: `${props.colorScheme}.800`,
      color: 'mf.white'
    }
  },
  title: {}
}))

export const alertTheme = defineMultiStyleConfig({ variants: { subtle } })
