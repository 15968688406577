/**
 * Enums
 */

/**
 * @enum {string}
 * @description Represents the possible skins for the admin layout, either light or dark.
 */
const MFAdminLayoutSkin = {
  LIGHT: 'light',
  DARK: 'dark'
}

/**
 * @enum {string}
 * @description Represents the possible states for the menu (sidebar).
 */
const MFAdminLayoutMenuSize = {
  DOCK: 'dock',
  FULL: 'full',
  NONE: 'none',
  DRAWER: 'drawer'
}

/**
 * @enum {string}
 * @description Represents the possible transitions for the router in the admin layout.
 */
const MFAdminLayoutRouterTransition = {
  FADE_IN: 'fadeIn',
  FADE_IN_LEFT: 'fadeInLeft',
  ZOOM_IN: 'zoomIn',
  NONE: 'none'
}

/**
 * @enum {string}
 * @description Represents the possible content widths for the admin layout, either full or boxed.
 */
const MFAdminLayoutContentWidth = {
  FULL: 'full',
  BOXED: 'boxed'
}

/**
 * @enum {string}
 * @description Represents the possible types for the navbar in the admin layout.
 */
const MFAdminLayoutNavbarType = {
  STATIC: 'static',
  STICKY: 'sticky',
  FLOATING: 'floating',
  HIDDEN: 'hidden'
}

/**
 * @enum {string}
 * @description Represents the possible background colors for the navbar in the admin layout.
 */
const MFAdminLayoutNavbarBackgroundColor = {
  WHITE: 'white',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  DANGER: 'danger',
  INFO: 'info',
  WARNING: 'warning',
  DARK: 'dark'
}

/**
 * @enum {string}
 * @description Represents the possible types for the footer in the admin layout.
 */
const MFAdminLayoutFooterType = {
  STATIC: 'static',
  STICKY: 'sticky',
  HIDDEN: 'hidden'
}

/**
 * @enum {string}
 * @description Represents the possible environments for the admin application.
 */
const MFAdminEnv = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEMO: 'demo',
  DEVELOPMENT: 'development'
}

/**
 * @enum {string}
 * @description Represents the possible action types for the admin initializer.
 */
const MFAdminInitializerActionType = {
  SET_RTL: 'SET_RTL',
  SET_SKIN: 'SET_SKIN',
  SET_ROUTER_TRANSITION: 'SET_ROUTER_TRANSITION',
  SET_CONTENT_WIDTH: 'SET_CONTENT_WIDTH',
  SET_MENU_STATE: 'SET_MENU_STATE',
  SET_MENU_COLLAPSED: 'SET_MENU_COLLAPSED',
  SET_NAVBAR_TYPE: 'SET_NAVBAR_TYPE',
  SET_NAVBAR_BACKGROUND_COLOR: 'SET_NAVBAR_BACKGROUND_COLOR',
  SET_FOOTER_TYPE: 'SET_FOOTER_TYPE',
  SET_CUSTOMIZER: 'SET_CUSTOMIZER',
  SET_SCROLL_TOP: 'SET_SCROLL_TOP'
}

/**
 * Type Definitions
 */

/**
 * @template T
 * @template P
 * @typedef {Object} MFAdminInitializerAction
 * @property {T} type - The type of action.
 * @property {P} payload - The payload of the action.
 */

/**
 * @typedef {'light' | 'dark'} MFAdminLayoutSkinType
 * @description Represents the possible skins for the admin layout, either light or dark.
 */

/**
 * @typedef {'fadeIn' | 'fadeInLeft' | 'zoomIn' | 'none'} MFAdminLayoutRouterTransitionType
 * @description Represents the possible transitions for the router in the admin layout.
 */

/**
 * @typedef {'full' | 'boxed'} MFAdminLayoutContentWidthType
 * @description Represents the possible content widths for the admin layout, either full or boxed.
 */

/**
 * @typedef {'static' | 'sticky' | 'floating' | 'hidden'} MFAdminLayoutNavbarTypeType
 * @description Represents the possible types for the navbar in the admin layout.
 */

/**
 * @typedef {'white' | 'primary' | 'secondary' | 'success' | 'danger' | 'info' | 'warning' | 'dark'} MFAdminLayoutNavbarBackgroundColorType
 * @description Represents the possible background colors for the navbar in the admin layout.
 */

/**
 * @typedef {'static' | 'sticky' | 'hidden'} MFAdminLayoutFooterTypeType
 * @description Represents the possible types for the footer in the admin layout.
 */

/**
 * @typedef {'production' | 'staging' | 'demo' | 'development'} MFAdminEnvType
 * @description Represents the possible environments for the admin application.
 */

/** @typedef {'dock' | 'full' | 'none' | 'drawer'} MFSideBarSize */

/**
 * @typedef {Object} MFAdminLayoutMenu
 * @property {MFSideBarSize} state - The current state of the sidebar.
 * @property {boolean} expandOnHover - Indicates if the menu should expand from dock when hovered.
 * @description Represents the state of the menu in the admin layout.
 */

/**
 * @typedef {Object} MFAdminLayoutNavbar
 * @property {MFAdminLayoutNavbarTypeType} type - The type of the navbar.
 * @property {MFAdminLayoutNavbarBackgroundColorType} backgroundColor - The background color of the navbar.
 * @description Represents the configuration of the navbar in the admin layout.
 */

/**
 * @typedef {Object} MFAdminLayoutFooter
 * @property {MFAdminLayoutFooterTypeType} type - The type of the footer.
 * @description Represents the configuration of the footer in the admin layout.
 */

/**
 * @typedef {Object} MFAdminLayout
 * @property {boolean} isRTL - Indicates if the layout is right-to-left.
 * @property {MFAdminLayoutSkinType} skin - The skin of the layout.
 * @property {MFAdminLayoutRouterTransitionType} routerTransition - The transition for the router.
 * @property {MFAdminLayoutContentWidthType} contentWidth - The content width of the layout.
 * @property {MFAdminLayoutMenu} menu - The menu configuration.
 * @property {MFAdminLayoutNavbar} navbar - The navbar configuration.
 * @property {MFAdminLayoutFooter} footer - The footer configuration.
 * @property {boolean} customizer - Indicates if the customizer is enabled.
 * @property {boolean} scrollTop - Indicates if the scroll-to-top feature is enabled.
 * @description Represents the overall layout configuration for the admin.
 */

/**
 * @typedef {Object} MFAdminInitializerContextState
 * @property {boolean} isInitialized - Indicates whether all initialization tasks have completed.
 * @property {MFAdminEnvType} env - The current environment.
 * @property {MFAdminLayout} layout - The layout configuration.
 * @description Represents the state of the initializer context.
 */

/**
 * @typedef {Object} MFAdminLayoutActions
 * @property {(payload: boolean) => void} SET_RTL - Action to set the right-to-left state.
 * @property {(payload: MFAdminLayoutSkinType) => void} SET_SKIN - Action to set the layout skin.
 * @property {(payload: MFAdminLayoutRouterTransitionType) => void} SET_ROUTER_TRANSITION - Action to set the router transition.
 * @property {(payload: MFAdminLayoutContentWidthType) => void} SET_CONTENT_WIDTH - Action to set the content width.
 * @property {(payload: MFSideBarSize) => void} SET_MENU_STATE - Action to set the menu state.
 * @property {(payload: boolean) => void} SET_MENU_COLLAPSED - Action to set the menu collapsed state.
 * @property {(payload: MFAdminLayoutNavbarTypeType) => void} SET_NAVBAR_TYPE - Action to set the navbar type.
 * @property {(payload: MFAdminLayoutNavbarBackgroundColorType) => void} SET_NAVBAR_BACKGROUND_COLOR - Action to set the navbar background color.
 * @property {(payload: MFAdminLayoutFooterTypeType) => void} SET_FOOTER_TYPE - Action to set the footer type.
 * @property {(payload: boolean) => void} SET_CUSTOMIZER - Action to set the customizer state.
 * @property {(payload: boolean) => void} SET_SCROLL_TOP - Action to set the scroll-to-top feature.
 * @description Represents the actions available for the layout state management.
 */

/**
 * @typedef {Object} MFAdminInitializerContextActions
 * @property {MFAdminLayoutActions} layout - The layout actions.
 * @description Represents the actions available for the initializer context state management.
 */

/**
 * @typedef {Object} MFAdminInitializerContext
 * @property {MFAdminInitializerContextState} state - The current state of the initializer context.
 * @property {MFAdminInitializerContextActions} actions - The available actions to modify the state.
 * @description Represents the context for the admin initializer, including state and actions.
 */

// Exporting Enums and Types
export {
  MFAdminInitializerActionType,
  MFAdminLayoutSkin,
  MFAdminLayoutRouterTransition,
  MFAdminLayoutContentWidth,
  MFAdminLayoutNavbarType,
  MFAdminLayoutNavbarBackgroundColor,
  MFAdminLayoutFooterType,
  MFAdminLayoutMenuSize,
  MFAdminEnv
}
