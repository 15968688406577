import { Badge } from '@chakra-ui/react'
import { useMemo } from 'react'

import { useMFAdminAppInitializer } from '../../contexts/MFAdminAppInitializer'

/**
 * @typedef {Object} MFEnvBadgeProps
 * @property {boolean} expanded - Indicates whether the badge is in expanded state.
 * @property {import('@chakra-ui/react').BadgeProps} BadgeProps - Props for the Chakra UI Badge component.
 */

/**
 *
 * @param {MFEnvBadgeProps & import('@chakra-ui/react').BadgeProps} props
 * @returns {JSX.Element}
 */
export default function MFEnvBadge({ expanded = false, ...props }) {
  const {
    state: { env }
  } = useMFAdminAppInitializer()

  const envBadgeConfig = useMemo(() => {
    const production = {
      children: expanded ? 'prod' : 'P',
      display: 'none'
    }

    const staging = {
      children: expanded ? 'staging' : 'stg',
      colorScheme: 'yellow'
    }

    const demo = {
      children: expanded ? 'pre-production' : 'pre',
      colorScheme: 'pink'
    }

    const development = {
      children: expanded ? 'development' : 'dev',
      colorScheme: 'green'
    }

    const propsMap = { production, staging, demo, development }

    return propsMap[env]
  }, [env, expanded])

  return (
    <Badge
      as='span'
      borderRadius="lg"
      variant="subtle"
      {...envBadgeConfig}
      {...props}
    />
  )
}
