import { Box, Drawer, DrawerContent, DrawerOverlay, useMediaQuery, VStack } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { useMFNav } from './MFNavContext'

// Default styles for the MF sidebar container.
const mFSidebarContainerStyles = {
  bg: 'mf.lightSurface',
  color: 'mf.black',
  borderRight: '1px solid',
  borderColor: 'mf.neutral.50',

  _dark: {
    bg: 'mf.black',
    color: 'mf.white',
    borderColor: 'mf.neutral.900'
  }
}

/**
 * MFSidebarContainer component handles the layout and display of the sidebar.
 *
 * This component adapts its display based on the screen size and the current state of the navigation menu.
 * It either displays a sidebar as a fixed box on larger screens or as a drawer on mobile devices.
 * The sidebar can be in a "dock" state, which expands on hover, or a "drawer" state for mobile views.
 *
 * @param {Object} props - The properties for the component.
 * @param {React.ReactNode} props.children - The children components to be rendered inside the sidebar.
 * @returns {JSX.Element} The rendered sidebar container.
 */
export default function MFSidebarContainer({ children }) {
  const { menu, isOpen, onClose, SET_IS_HOVERED } = useMFNav()

  const [isMobile] = useMediaQuery('(max-width: 29.98em)')

  if (menu.state === 'drawer') {
    return (
      <Drawer
        placement="left"
        variant="nav"
        // size="nav"
        isOpen={isOpen}
        onClose={onClose}
        zIndex="tooltip"
        // sx={mFSidebarContainerStyles}
      >
        <DrawerOverlay onClick={onClose} zIndex="overlay" />
        <DrawerContent maxW={isMobile ? 'full' : '56'}>
          <VStack h="full" justifyContent="space-between" alignItems="flex-start">
            {children}
          </VStack>
        </DrawerContent>
      </Drawer>
    )
  }

  return (
    <Box
      as={motion.div}
      layout
      layoutRoot
      initial={{ width: '4rem' }}
      whileHover={{ width: '14rem' }}
      sx={mFSidebarContainerStyles}
      hidden={menu.state === 'none'}
      position="fixed"
      top="0"
      left="0"
      h="100vh"
      minW={menu.state === 'dock' && !menu.isHovered ? '16' : '56'}
      zIndex="modal"
      onMouseEnter={() => SET_IS_HOVERED(true)}
      onMouseLeave={() => SET_IS_HOVERED(false)}
    >
      <VStack h="full" justifyContent="space-between" alignItems="flex-start">
        {children}
      </VStack>
    </Box>
  )
}

MFSidebarContainer.propTypes = {
  children: PropTypes.node.isRequired
}
