import _ from 'lodash'
import {
  FETCH_PORTALS,
  CREATE_PORTAL,
  FETCH_PORTAL,
  EDIT_PORTAL,
  DELETE_PORTAL,
  CHECK_PORTAL_CODE
} from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PORTALS:
      return { ..._.mapKeys(action.payload, 'id') }
    case FETCH_PORTAL:
      return { ...state, [action.payload.id]: action.payload }
    case CREATE_PORTAL:
      return { ...state, [action.payload.id]: action.payload }
    case EDIT_PORTAL:
      return { ...state, [action.payload.id]: action.payload }
    case DELETE_PORTAL:
      return _.omit(state, action.payload)
    case CHECK_PORTAL_CODE:
      return { ...state, [action.payload.id]: action.payload }
    default:
      return state
  }
}
