import { useColorMode } from '@chakra-ui/react'
import themes from 'devextreme/ui/themes'
import PropTypes from 'prop-types'
import { useMemo, useReducer, useState, useCallback, useEffect } from 'react'

import { NODE_ENV, REACT_APP_BACKEND_API_URL } from '../../Constants'
import { layoutActions } from './MFAdminAppInitializer.actions'
import {
  MFAdminInitializerStateContext,
  MFAdminInitializerActionsContext,
  initialContext
} from './MFAdminAppInitializer.context'
import mfAdminInitializerReducer from './MFAdminAppInitializer.reducer'

export default function MFAdminAppInitializerProvider({ children }) {
  const [isInitialized, setIsInitialized] = useState(initialContext.isInitialized)
  const [env, setEnv] = useState(initialContext.env)
  const [overlay, setOverlay] = useState(false)

  const [layout, layoutDispatch] = useReducer(mfAdminInitializerReducer, initialContext.state.layout)

  const { colorMode, toggleColorMode } = useColorMode()

  /** @type {import('./MFAdminAppInitializer.types').MFAdminInitializerContextState} */
  const stateValue = useMemo(
    () => ({
      isInitialized,
      env,
      layout
    }),
    [isInitialized, env, layout]
  )

  /** @type {import('./MFAdminAppInitializer.types').MFAdminInitializerContextActions} */
  const actionsValue = useMemo(
    () => ({
      setOverlay, // THIS IS A TEMPORARY FIX!!!!
      layout: {
        SET_RTL: layoutActions.setRTL(layoutDispatch),
        SET_SKIN: payload => {
          toggleColorMode(colorMode === 'light' ? 'Dark' : 'Light')
          layoutActions.setSkin(layoutDispatch)(payload)
        },
        SET_ROUTER_TRANSITION: layoutActions.setRouterTransition(layoutDispatch),
        SET_CONTENT_WIDTH: layoutActions.setContentWidth(layoutDispatch),
        SET_MENU_STATE: layoutActions.setMenuState(layoutDispatch),
        SET_MENU_COLLAPSED: layoutActions.setMenuCollapsed(layoutDispatch),
        SET_NAVBAR_TYPE: layoutActions.setNavbarType(layoutDispatch),
        SET_NAVBAR_BACKGROUND_COLOR: layoutActions.setNavbarBackgroundColor(layoutDispatch),
        SET_FOOTER_TYPE: layoutActions.setFooterType(layoutDispatch),
        SET_CUSTOMIZER: layoutActions.setCustomizer(layoutDispatch),
        SET_SCROLL_TOP: layoutActions.setScrollTop(layoutDispatch)
      }
    }),
    [layoutDispatch, setOverlay, toggleColorMode, colorMode]
  )

  // Determines the current environment and sets the state accordingly
  const getCurrentEnvironment = useCallback(() => {
    const environment = NODE_ENV
    const backendUrl = REACT_APP_BACKEND_API_URL

    if (!backendUrl) {
      alert('Missing environment variable: REACT_APP_BACKEND_API_URL')
      setEnv(undefined)
      setIsInitialized(false)
    }

    if (environment !== 'development' && backendUrl.indexOf('staging') !== -1) {
      setEnv('staging')
    } else if (environment && backendUrl.indexOf('demo') !== -1) {
      setEnv('demo')
    } else if (environment && environment !== 'production') {
      setEnv('development')
    } else {
      setEnv(environment)
    }

    // setIsInitialized(true)
  }, [])

  useEffect(() => {
    getCurrentEnvironment()
  }, [getCurrentEnvironment])

  // Updates metadata links (favicon and manifest) based on the current environment
  const handleMetaData = useCallback(() => {
    const faviconLink = document.querySelector("link[rel~='icon']")
    const manifestLink = document.querySelector("link[rel~='manifest']")

    switch (env) {
      case 'development':
        faviconLink.href = '/public/favicon_development.ico'
        manifestLink.href = '/public/manifest_development.json'
        break
      case 'staging':
        faviconLink.href = '/public/favicon_staging.ico'
        manifestLink.href = '/public/manifest_staging.json'
        break
      case 'demo':
        faviconLink.href = '/public/favicon_demo.ico'
        manifestLink.href = '/public/manifest_demo.json'
        break
      default:
        break
    }
  }, [env])

  useEffect(() => {
    handleMetaData()
  }, [handleMetaData])

  const handleLayoutClassList = useCallback(() => {
    const element = window.document.body

    const darkClass = 'dark-layout'

    if (stateValue.layout.skin === 'dark' && !element.classList.contains(darkClass)) element.classList.add(darkClass)
    else element.classList.remove(darkClass)

    // eslint-disable-next-line import/no-named-as-default-member
    themes.current(`generic.${stateValue.layout.skin === 'dark' ? 'dark' : 'softblue'}`)
  }, [stateValue.layout.skin])

  useEffect(() => {
    handleLayoutClassList()
  }, [handleLayoutClassList])

  return (
    <MFAdminInitializerStateContext.Provider value={stateValue}>
      <MFAdminInitializerActionsContext.Provider value={actionsValue}>
        <div id="spinner-overlay" className={overlay ? '' : 'hidden'} />
        {children}
      </MFAdminInitializerActionsContext.Provider>
    </MFAdminInitializerStateContext.Provider>
  )
}

MFAdminAppInitializerProvider.propTypes = {
  children: PropTypes.node
}
