// ** React Imports
import classnames from 'classnames'
import { Fragment, useEffect, useMemo } from 'react'

// ** Third Party Components

// ** Store & Actions
import { useSelector, useDispatch } from 'react-redux'
import { handleContentWidth, handleMenuCollapsed, handleMenuHidden } from '../../../../redux/actions/layout'

// ** Styles
import 'animate.css/animate.css'
import { Box, Container, useMediaQuery } from '@chakra-ui/react'
import { useMFAdminAppInitializer } from '../../../../contexts/MFAdminAppInitializer'
import { motion } from 'framer-motion'

const LayoutWrapper = props => {
  // ** Props
  const { layout, children, appLayout, wrapperClass, transition, routeMeta } = props

  const {
    state: {
      layout: { menu }
    }
  } = useMFAdminAppInitializer()

  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector(state => state)
  const navbarStore = store.navbar
  const contentWidth = store.layout.contentWidth

  //** Vars
  const Tag = layout === 'HorizontalLayout' && !appLayout ? 'div' : Fragment

  // ** Clean Up Function
  const cleanUp = () => {
    if (routeMeta) {
      if (routeMeta.contentWidth) {
        dispatch(handleContentWidth('full'))
      }
      if (routeMeta.menuCollapsed) {
        dispatch(handleMenuCollapsed(!routeMeta.menuCollapsed))
      }
      if (routeMeta.menuHidden) {
        dispatch(handleMenuHidden(!routeMeta.menuHidden))
      }
    }
  }

  // ** ComponentDidMount
  useEffect(() => {
    if (routeMeta) {
      if (routeMeta.contentWidth) {
        dispatch(handleContentWidth(routeMeta.contentWidth))
      }
      if (routeMeta.menuCollapsed) {
        dispatch(handleMenuCollapsed(routeMeta.menuCollapsed))
      }
      if (routeMeta.menuHidden) {
        dispatch(handleMenuHidden(routeMeta.menuHidden))
      }
    }
    return () => cleanUp()
  }, [])

  const [isDesktop] = useMediaQuery('(min-width: 62em)')

  const appConetentMargin = useMemo(() => {
    if (isDesktop) {
      if (menu.state === 'full') return '64'
      if (menu.state === 'dock') return '16'
    }

    return '0'
  }, [menu.state, isDesktop])

  return (
    <Container
      // as={motion.main}
      // layout
      maxW="7xl"
      w={`calc(--mf-sizes-7xl - ${appConetentMargin})`}
      ml={appConetentMargin}
      mt="16"
      // className={classnames('app-content content overflow-hidden', {
      //   [wrapperClass]: wrapperClass,
      //   'show-overlay': navbarStore.query.length
      // })}
    >
      {/* <div className='content-overlay'></div> */}
      <div className="header-navbar-shadow" />
      <div
        className={classnames({
          'content-wrapper': !appLayout,
          'content-area-wrapper': appLayout,
          'container p-0': contentWidth === 'boxed',
          [`animate__animated animate__${transition}`]: transition !== 'none' && transition.length
        })}
      >
        <Tag
          {...(layout === 'HorizontalLayout' && !appLayout
            ? { className: classnames({ 'content-body': !appLayout }) }
            : {})}
        >
          {children}
        </Tag>
      </div>
    </Container>
  )
}

export default LayoutWrapper
