import { Box, Container } from '@chakra-ui/react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import heroBg from '../../assets/images/backgrounds/hero-bg-icons.png'
import Auth0Callback from '../../components/sessions/Auth0Callback'
import ForgotPasswordForm from '../../components/sessions/ForgotPasswordForm'
import ResetPasswordForm from '../../components/sessions/ResetPasswordForm'
import SessionNew from '../../components/sessions/SessionNew'

const SessionsLayout = ({ currentAdmin, session, loading }) => {
  const loggedIn = session || currentAdmin?.id > 0
  return (
    <Box
      backgroundImage={`url(${heroBg})`}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      // bgGradient={{ sm: 'linear(to-r, mf.primary.200, mf.primary.400)' }}
      // _dark={{ bgGradient: { sm: 'linear(to-r, mf.primary.400, mf.primary.600)' } }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      h="100vh"
    >
      <Box
        p={{ base: 0, sm: 2 }}
        bg="mf.whiteAlpha.400"
        borderRadius={{ base: 'none', sm: 'xl' }}
        boxShadow={{ base: 'none', sm: 'xl' }}
      >
        <Container
          maxW="lg"
          maxH={{ base: '100vh' }}
          minH={{ base: '100vh', sm: 'auto' }}
          height={{ base: '100vh', sm: '100%' }}
          width={{ base: '100vw', sm: 'lg' }}
          py={{ base: '8', sm: '8' }}
          px={{ base: '4', sm: '10' }}
          bg="mf.lightSurface"
          _dark={{ bg: 'mf.black' }}
          // boxShadow={{ base: 'none', sm: 'xl' }}
          borderRadius={{ base: 'none', sm: 'md' }}
        >
          <Switch>
            {loggedIn && !loading.session && !loading.admin ? <Redirect to={'/'} /> : null}
            <Route exact path="/login" component={SessionNew} />
            <Route exact path="/forgot_password" component={ForgotPasswordForm} />
            <Route exact path="/reset_password/:token" component={ResetPasswordForm} />
            <Route exact path="/auth0_callback" component={Auth0Callback} />
            {/* <Route exact path="/sso-log-in/:sso_id/:sso_token"  component={SSoSignInPage} /> */}
          </Switch>
        </Container>
      </Box>
    </Box>
  )
}

const mapStateToProps = state => {
  return {
    currentAdmin: state.admins.current_admin,
    session: state.session,
    loading: state.loading
  }
}

export default connect(mapStateToProps)(SessionsLayout)
