import { defineStyleConfig } from '@chakra-ui/react'

/**
 * Base styles configuration for the `link` component.
 *
 * This base style is applied to all `link` components unless explicitly overridden.
 * It includes styles for interaction states, such as hover, where it disables the default
 * text decoration (underline) to ensure a clean and consistent look across the application.
 *
 * - `_hover`: The hover state for the link, where the text decoration is removed to
 *   prevent underlining.
 *
 * @type {Object}
 */
const baseStyle = props => ({
  color: `${props.colorScheme}.800 !important`,
  textDecoration: 'underline !important',

  _hover: {
    color: `${props.colorScheme}.600 !important`
  },

  _dark: {
    color: `${props.colorScheme}.200 !important`,

    _hover: {
      color: `${props.colorScheme}.100 !important`
    }
  }
})

/**
 * The theme configuration for the `link` component.
 *
 * This configuration includes the base styles that will be applied to all `link` components
 * in the application. Additional styles or variants can be added in the future if needed.
 *
 * - `baseStyle`: The default styles applied to all links, focusing on interaction states.
 *
 * For more information on theming the `link` component, visit the [Chakra UI Link Theming Documentation](https://v2.chakra-ui.com/docs/components/link/theming).
 *
 * @type {Object}
 */
export const linkTheme = defineStyleConfig({ baseStyle })
