import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  dialog: {
    borderRadius: 'md',
    bg: 'mf.white',
    color: 'mf.black',

    _dark: {
      bg: 'mf.black',
      color: 'mf.white'
    }
  },

  overlay: {
    bg: 'mf.blackAlpha.300',
    backdropFilter: 'blur(2.4px) saturate(90%)'
  }
})

export const modalTheme = defineMultiStyleConfig({
  baseStyle
})
