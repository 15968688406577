import { connect } from 'react-redux'
import Select from 'react-select'
import { COLORS } from '../../Constants'

const MfSelect = props => {
  const { themeMode, customStyles } = props
  const isLightMode = themeMode === 'light'

  // Merge custom styles with default styles
  const mergeStyles = (defaultStylesFn, state, styleName) => {
    const defaultStyles = defaultStylesFn(state)

    if (customStyles && typeof customStyles === 'object' && customStyles.hasOwnProperty(styleName)) {
      return {
        ...defaultStyles,
        ...customStyles[styleName](defaultStyles, state)
      }
    }
    return defaultStyles
  }

  // Define styles prop for react-select
  const mfSelectStyles = {
    control: (baseStyles, state) =>
      mergeStyles(
        () => ({
          ...baseStyles,
          backgroundColor: isLightMode ? baseStyles.backgroundColor : '#2b2b2b'
        }),
        state,
        'control'
      ),
    menu: (baseStyles, state) =>
      mergeStyles(
        () => ({
          ...baseStyles,
          backgroundColor: isLightMode ? baseStyles.backgroundColor : '#2b2b2b',
          color: `${COLORS.fontPrimary}`,
          zIndex: 9
        }),
        state,
        'menu'
      ),
    singleValue: (baseStyles, state) =>
      mergeStyles(
        () => ({
          ...baseStyles,
          color: isLightMode ? baseStyles.color : '#dedede'
        }),
        state,
        'singleValue'
      ),
    option: (baseStyles, state) =>
      mergeStyles(
        () => {
          const { isFocused, isSelected } = state
          const optionStyles = {}
          if (isSelected) {
            optionStyles.backgroundColor = COLORS.primary
            optionStyles.color = '#eeeeee'
          } else if (isFocused && !isLightMode) {
            optionStyles.color = '#00a0dd'
          }
          return {
            ...baseStyles,
            ...optionStyles
          }
        },
        state,
        'option'
      ),
    container: (baseStyles, state) =>
      mergeStyles(
        () => ({
          ...baseStyles
        }),
        state,
        'container'
      ),
    placeholder: (baseStyles, state) =>
      mergeStyles(
        () => ({
          ...baseStyles,
          color: isLightMode ? 'hsl(0, 0%, 20%)' : '#eeeeee'
        }),
        state,
        'placeholder'
      ),
    valueContainer: (baseStyles, state) =>
      mergeStyles(
        () => ({
          ...baseStyles
        }),
        state,
        'valueContainer'
      ),
    dropdownIndicator: (baseStyles, state) =>
      mergeStyles(
        () => ({
          ...baseStyles
        }),
        state,
        'dropdownIndicator'
      ),
    indicatorSeparator: (baseStyles, state) =>
      mergeStyles(
        () => ({
          ...baseStyles
        }),
        state,
        'indicatorSeparator'
      )
  }

  return (
    <>
      <Select
        ref={props.selectRef}
        {...props}
        // modify styles for Dark Mode
        styles={mfSelectStyles}
        menuPlacement={props.menuPlacement}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    themeMode: state.layout.themeMode
  }
}

export default connect(mapStateToProps)(MfSelect)
